import React from "react";
import ContactTile from "./ContactTile";
import { Contact } from "../../../lib/CmsApi";
import HeaderWithIntro from "../HeaderWithIntro";
import Slider from "../../Slider";

export interface ContactsSliderProps {
  headline: string;
  copy?: string;
  small?: boolean;
  withImage?: boolean;
  noBackground?: boolean;
  contacts?: { contact: Contact; role: string }[];
  alwaysMobileControls?: boolean;
}

export default function ContactsSlider(props: ContactsSliderProps) {
  const {
    small,
    headline,
    contacts,
    copy,
    withImage,
    noBackground,
    alwaysMobileControls
  } = props;
  if (!contacts) return null;

  return (
    <>
      <HeaderWithIntro
        headline={headline}
        intro={copy}
        size={small ? 4 : 1}
        boldHeadline={small}
        blue
        small
      />

      <Slider
        itemCountXLarge={small ? 2 : 3}
        itemCountLarge={small ? 2 : 3}
        itemCountMedium={small ? 2 : 2}
        itemCountSmall={1}
        ariaLabel={headline}
        alwaysMobileControls={alwaysMobileControls}
        padded
      >
        {contacts.map(({ role, contact }, index) => {
          return (
            <ContactTile
              role={role}
              {...contact}
              key={index}
              withImage={withImage}
              invertWhite={noBackground}
              stretch
            />
          );
        })}
      </Slider>
    </>
  );
}

ContactsSlider.getImages = (props: ContactsSliderProps) => {
  if (!props.contacts) return [];

  return props.contacts
    .map(c => props.withImage && c.contact.image)
    .filter(Boolean);
};
