import TextTeaserComponent, {
  TextTeaserProps
} from "../common/TextTeaserComponent";
import { withSection } from "./Section";
import React from "react";

function LinkTextTeaser({ teaser }: { teaser: TextTeaserProps }) {
  return <TextTeaserComponent {...teaser} />;
}
const LinkTextTeaserSection = withSection<{
  teaser: TextTeaserProps;
}>(LinkTextTeaser);
export default LinkTextTeaserSection;
