import React, { useCallback, useEffect, useState } from "react";
import { ContentRef, Media, MediaRef } from "../../lib/CmsApi";
import { FurtherInfoType, FurtherInfoBanner } from "./FurtherInfoBanner";
import styled, { css } from "styled-components";
import { THEME_SCALE, breakpoints, colors, font, media } from "../theme";
import Headline from "./Headline";
import {
  RightArrowSm,
  InfoIcon,
  sustainabilityIconTiles,
  sustainabilityIconTilesSmall,
  InfoGraphicChart
} from "./icons";
import { MobileInfoGraphic } from "./MobileInfoGraphic";
import useMediaQuery from "../../lib/useMediaQuery";

export interface SDGProps {
  id?: string;
  intro?: {
    headline: string;
    introText: string;
    ctaRef?: ContentRef;
    ctaLabel?: string;
  }[];
  infoGraphicTitle: string;
  infoGraphicBanner?: {
    headline: string;
    introText: string;
  }[];
  sustainableDevelopmentBanner?: {
    headline: string;
    introText: string;
  }[];
  furtherInfo?: FurtherInfoType;
}

const SustainabilityGoalsContainer = styled.div`
  padding-top: 48px;
  // padding-bottom: 48px;
  @media screen and ${media.tablet} {
    width: 100%;
  }
`;

const IntroBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1300px;
  padding: 0px;

  width: 100%;
  height: 208px;

  margin-top: 20px;
  margin-bottom: 60px;

  //padding added as Section parent margin removed to display full width infographic
  padding-left: 8%;
  padding-right: 8%;

  @media screen and ${media.tablet} {
    height: auto;
  }

  @media screen and ${media.mobile} {
    width: 100%;
    height: 308px;
    left: 0px;
    top: 0px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-bottom: 48px;
  }

  @media screen and ${media.tiny} {
    height: auto;
  }
`;

const StyledIntroTitle = styled(Headline)`
  grid-area: title;
`;

const StyledIntroText = styled.p`
  grid-area: paragraph;
`;

const StyledFooterText = styled(Headline)`
  grid-area: title;

  color: #00456a;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #3c6c92;
  }
`;

const TilesContainer = styled.div`
  padding-top: 48px;
  margin-bottom: 80px;
  padding-left: 8%;
  padding-right: 14%;

  @media screen and ${media.mobile} {
    justify-content: center;
    margin-bottom: 10px;
  }
  @media screen and ${media.tiny} {
    justify-content: center;
    margin-bottom: 10px;
  }
  @media screen and ${media.superTiny} {
    margin-bottom: 10px;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const InfoGraphicContainer = styled.div`
  margin-bottom: 48px;

  @media screen and ${media.mobile} {
    margin-top: 50px;
  }

  @media screen and ${media.tiny} {
    margin-top: 50px;
  }
  @media screen and ${media.superTiny} {
    margin-top: 50px;
  }
`;

const StyledGraphicTitle = styled(Headline)`
  grid-area: title;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 48px;
`;

const IGChartMain = styled.div`
  margin-top: 148px;
  margin-bottom: 148px;
`;

const IGBase = styled.div`
  width: 100%;
  height: 600px;
  top: 216px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    .slice {
      fill: #cedbe7;
    }

    .parent:hover .pie_stat path {
      fill: white;
    }

    .seven:hover path {
      fill: #f9b00b;
      transition: fill 500ms;
      cursor: pointer;
    }

    .eight:hover path {
      fill: #8C1535;
      transition: all 300ms ease-out;
      cursor: pointer;
    }

    .nine:hover path {
      fill: #f25a1d;
      transition: all 300ms ease-out;
      cursor: pointer;
    }

    .ten:hover path {
      fill: #e10a85;
      transition: all 300ms ease-out;
      cursor: pointer;
    }

    .eleven:hover path {
      fill: #f79118;
      transition: all 300ms ease-out;
      cursor: pointer;
    }

    .thirteen:hover path {
      fill: #407534;
      transition: all 300ms ease-out;
      cursor: pointer;
    }

    .one:hover path {
      fill: #EB1A2D;
      transition: all 300ms ease-out;
      cursor: pointer;
    }
`;

const IGBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 400px;
  background-color: #cedbe7;
`;

const LowerTextBanner = styled.div`
  max-width: 1300px;
  margin-left: 8%;
  margin-right: 28%;
  width: 80%;
  margin-top: 48px;
  height: 158px;
  // background-color: #ffffff;
  background-color: ${colors.pigeonSuperLight};
  border-radius: 10px;
  gap: 29px;
  padding-top: 36px;
  padding-left: 10px;
  padding-right: 5px;

  @media screen and ${media.mobile} {
    width: 80%;
    height: 282px;
    padding: 24px;
    border-radius: 16px;
    gap: 29px;
    padding-top: 5px;
  }
  @media screen and ${media.tablet} {
    width: 80%;
    height: 200px;
  }
  @media screen and ${media.tiny} {
    width: 80%;
    height: 220px;
    padding: 24px;
    border-radius: 16px;
    gap: 29px;
    // padding-top: 10px;
  }
  @media screen and ${media.superTiny} {
    height: 250px;
    padding-top: 20px;
    width: 80%;
  }
`;

const LowerTitle = styled(Headline)`
  grid-area: title;
  margin-bottom: 16px;
`;

const LowerText = styled.p`
  height: 48px;
  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 390;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  @media screen and ${media.tablet} {
    font-size: 14px;
  }

  @media screen and ${media.mobile} {
    font-size: 12px;
  }
`;

const DevelopmentBanner = styled.div<{ showMore: boolean }>`
  margin-top: 48px;
  // margin-bottom: 100px;

  width: 80%;
  margin-left: 8%;
  margin-right: 28%;

  width: auto;
  height: 226px;

  @media screen and ${media.tablet} {
    width: 80%;
    margin-bottom: 80px;
  }

  @media screen and ${media.mobile} {
    width: 80%;
    height: 388px;
    left: 0px;
    top: 0px;
    // padding-top: 32px;
    margin-bottom: 32px;
  }

  @media screen and ${media.tiny} {
    width: 80%;
    height: ${p => (p.showMore ? "100%" : "300px")};
    margin-bottom: ${p => (p.showMore ? "20%" : "100px")};
    transition: all 0.4s;
  }

  @media screen and ${media.superTiny} {
    width: 80%;
    height: 100%;
    margin-bottom: ${p => (p.showMore ? "20%" : "80px")};
    transition: all 0.4s;
  }

  @media screen and ${media.superTinyZoom125} {
    width: 80%;
    height: 450px;
    margin-bottom: ${p => (p.showMore ? "20%" : "80px")};
    transition: all 0.4s;
  }
`;

const DevTitle = styled(Headline)`
  grid-area: title;
  margin-bottom: 16px;
  width: 100%;
`;

const DevText = styled.p`
  width: auto;
  font-family: Brandon Text;
  font-size: 18px;
  font-weight: 390;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;

  padding-right: 120px;

  @media screen and ${media.mobile} {
    font-size: 14px;
    padding-right: 10px;
    width: auto;
  }
`;

const MobileDevText = styled.p<{ showMore: boolean }>`
  height: ${p => (p.showMore ? "100%" : "200px")};
  margin-bottom: ${p => (p.showMore ? "20%" : "")};
  transition: all 0.7s;
  @media screen and ${media.tiny} {
    padding-right: 0px;
    -webkit-mask-image: ${p =>
      p.showMore
        ? ""
        : `-webkit-gradient(
        linear,
        left center,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      )`};
  }
`;

const FurtherInfoContainer = styled.div`
  justify-content: center;
  @media screen and ${media.tablet} {
    // margin-top: 200px;
  }
  @media screen and ${media.mobile} {
    margin-top: 32px;
  }

  @media screen and ${media.tiny} {
    margin-top: 32px;
    margin-right: 8%;
  }

  @media screen and ${media.superTiny} {
    margin-right: 8%;
  }
`;

const ShowMoreButton = styled.button`
  width: 100%;
  height: auto;
  align-self: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  gap: 10px;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #004669;
  white-space: nowrap;
  text-align: center;
  margin-top: 10px;

  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 420;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
`;

const SDGGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  column-gap: 5px;
  row-gap: 20px;
  min-width: 0px;

  @media screen and (max-width: ${breakpoints.wide}px) {
    grid-template-columns: repeat(5, 1fr);
    row-gap: 15px;
  }

  @media screen and ${media.mobile} {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  }

  @media screen and ${media.tiny} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 0px;
  }

  @media screen and ${media.superTiny} {
    justify-content: center;
  }
`;

const SDGTileRow = styled.div`
  grid-column: span 1;
`;

const StyledCTA = styled.a`
  color: #00456a;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #3c6c92;
  }
`;

export function SustainabilityGoalsComponent(p: SDGProps) {
  const isMobile = useMediaQuery(breakpoints.tiny);
  const isTablet = useMediaQuery(breakpoints.tablet);
  const isTiny = useMediaQuery(breakpoints.tiny);
  const isSuperTiny = useMediaQuery(breakpoints.superTiny);

  useEffect(() => {
    const svgChart = document.querySelector("#sustainability_piechart");
    const pieSlices = document.querySelectorAll(".parent");
    pieSlices.forEach(pieSlice => {
      const id = pieSlice.classList[1];

      pieSlice.addEventListener("mouseenter", e => {
        svgChart.appendChild(
          document.querySelector(`.${id} > .center_stat`).cloneNode(true)
        );
      });
      pieSlice.addEventListener("mouseleave", e => {
        svgChart.removeChild(
          document.querySelector("#sustainability_piechart > .center_stat")
        );
      });
    });
  }, [isMobile, isTablet, isTiny, isSuperTiny]);

  const [showMore, setShowMore] = useState(false);

  function handleChange(): void {
    if (!showMore) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }

  return (
    <SustainabilityGoalsContainer>
      <IntroBanner>
        <StyledIntroTitle
          size={3}
          key="introTitle"
          level={2}
          id={`introTitle_${p.id}`}
        >
          {p.intro[0].headline}
        </StyledIntroTitle>
        <StyledIntroText>{p.intro[0].introText}</StyledIntroText>
        <StyledCTA href={p.intro[0].ctaRef._url ? p.intro[0].ctaRef._url : ""}>
          <StyledFooterText size={5} key="footer" level={4}>
            {p.intro[0].ctaLabel} <RightArrowSm />
          </StyledFooterText>
        </StyledCTA>
      </IntroBanner>
      <TilesContainer>
        <SDGGrid>
          {isMobile || isTablet || isTiny || isSuperTiny
            ? sustainabilityIconTilesSmall.map((sdgTile, idx) => {
                return (
                  <SDGTileRow key={`sdgTile_${idx}`}>{sdgTile}</SDGTileRow>
                );
              })
            : sustainabilityIconTiles.map((sdgTile, idx) => {
                return (
                  <SDGTileRow key={`sdgTile_${idx}`}>{sdgTile}</SDGTileRow>
                );
              })}
        </SDGGrid>
      </TilesContainer>
      <InfoGraphicContainer>
        <StyledGraphicTitle
          size={isMobile ? 4 : 3}
          key="infoGraphicTitle"
          level={3}
          id={`intro_grafik_title_${p.id}`}
          style={{ marginBottom: isMobile ? "10px" : "48px" }}
        >
          {p.infoGraphicTitle}
        </StyledGraphicTitle>
        {!isMobile && !isTablet ? (
          <IGChartMain>
            <IGBase>
              <IGBackground>
                <InfoGraphicChart />
              </IGBackground>
            </IGBase>
          </IGChartMain>
        ) : (
          <MobileInfoGraphic />
        )}
      </InfoGraphicContainer>
      <LowerTextBanner>
        <LowerTitle
          size={5}
          key="lowertitle"
          level={4}
          id={`lowerTitle_${p.id}`}
        >
          <InfoIcon
            style={{ marginBottom: "-5px", marginRight: "5px" }}
            height={30 * THEME_SCALE}
            width={30 * THEME_SCALE}
          />
          {p.infoGraphicBanner[0].headline}
        </LowerTitle>
        <LowerText style={{}}>{p.infoGraphicBanner[0].introText}</LowerText>
      </LowerTextBanner>
      <DevelopmentBanner showMore={showMore}>
        <DevTitle size={4} level={4}>
          {p.sustainableDevelopmentBanner[0].headline}
        </DevTitle>
        {!isMobile && (
          <DevText>{p.sustainableDevelopmentBanner[0].introText}</DevText>
        )}
        {isMobile && (
          <MobileDevText showMore={showMore}>
            {p.sustainableDevelopmentBanner[0].introText}
          </MobileDevText>
        )}

        {isMobile && !showMore && (
          <ShowMoreButton
            onClick={handleChange}
            key="showMoreButton"
            aria-label={"show more button"}
          >
            {"Mehr anzeigen"}
          </ShowMoreButton>
        )}
      </DevelopmentBanner>
      <FurtherInfoContainer>
        <FurtherInfoBanner
          isSDG={true}
          isMobile={isMobile}
          furtherInfo={p.furtherInfo}
        />
      </FurtherInfoContainer>
    </SustainabilityGoalsContainer>
  );
}

SustainabilityGoalsComponent.getImages = (props: SDGProps) => {
  return props.furtherInfo.image;
};
