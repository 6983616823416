import React from "react";
import { MediaRef, Media, ContentRef } from "../../lib/CmsApi";
import styled, { css } from "styled-components";
import getImageFocus from "../../lib/getImageFocus";
import { responsiveBackgroundImage } from "../../lib/responsiveImage";
import { colors, media } from "../theme";
import { Image as ImageType } from "../../lib/image";
import Headline from "./Headline";

export type FurtherInfoType = {
  headline: string;
  text: string;
  image: MediaRef & Media;
  links?: {
    ctaLabel?: string;
    ctaRef?: ContentRef;
  }[];
};

export interface FurtherInfoProps {
  furtherInfo?: FurtherInfoType;
  isMobile?: boolean;
  isSDG?: boolean;
}

const BannerContainer = styled.div<{ isSDG: boolean }>`
  max-width: 1300px;
  width: ${p => (p.isSDG ? "80%" : "100%")};
  height: 283px;

  //margin to compensate for lack of margin on parent due to InfoGraphic
  margin-left: ${p => (p.isSDG ? "8%" : "")};
  margin-right: ${p => (p.isSDG ? "8%" : "")};

  margin-bottom: 20px;

  background-color: ${colors.pigeonSuperLight} !important;

  justify-content: center;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  column-gap: 5px;

  padding-right: 5px;

  border-radius: 10px;

  @media screen and ${media.mobile} {
    width: ${p => (p.isSDG ? "80%" : "100%")};
    padding-top: 10px;
    padding-left: 15px;
  }

  @media screen and ${media.tiny} {
    width: ${p => (p.isSDG ? "80%" : "100%")};
    height: 328px;
    padding-left: 20px;
    border-radius: 16px;
    gap: 29px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 90%;
  }

  @media screen and ${media.superTiny} {
    width: ${p => (p.isSDG ? "80%" : "100%")};
    padding-left: 30px;
  }
  @media screen and ${media.superTinyZoom125} {
    width: ${p => (p.isSDG ? "80%" : "100%")};
  }
`;

const InfoImage = styled.div<{ image: ImageType }>`
  width: 90%;
  height: 190px;

  padding-left: 15px;
  margin-left: 15px;
  margin-right: 5px;

  background-size: cover;
  background-position: ${p => getImageFocus(p.image)};
  @media screen and ${media.tablet} {
    height: 150px;
    width: 120px;
    margin-left: 15px;
    margin-bottom: 20px;
  }
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  ${p => responsiveBackgroundImage({ image: p.image })};
  box-shadow: 0px 20px 40px 0px #00456a33;
`;

const InfoBanner = styled.div`
  padding-bottom: 10px;
`;

const StyledTitle = styled(Headline)`
  grid-area: title;
`;

const StyledText = styled.p`
  //styleName: Copytext/Tiny-Desktop-1024;
  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 390;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: #4c4c4c;

  padding-right: 50px;

  @media screen and ${media.tablet} {
    font-size: 14px;
    padding-right: 50px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media screen and ${media.mobile} {
    display: block;
  }
`;

const ctaStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  gap: 10px;

  width: fit-content;
  height: 48px;

  background: #004669;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #004669;
  background-color: #ffffff;

  border: 2px solid #004669;

  border-radius: 4px;

  white-space: nowrap;
  text-align: center;
  margin-top: 10px;

  overflow: visible;
  padding: 2px 5px 2px 5px;
  margin-right: 10px;
  line-height: 40px;

  @media screen and ${media.tablet} {
    font-size: 16px;
    border: 1px solid #004669;
  }

  @media screen and ${media.mobile} {
    height: 40px;
  }
  @media screen and ${media.tiny} {
    height: 40px;
  }
`;
const StyledCTA = styled.a`
  ${ctaStyle}
  text-decoration: none;
  &:hover {
    color: #3c6c92;
    border: 2px solid #3c6c92;
  }
`;

export function FurtherInfoBanner(p: FurtherInfoProps) {
  const { headline, text, image, links } = p.furtherInfo;

  return (
    <BannerContainer isSDG={p.isSDG} style={{ backgroundColor: colors.white }}>
      {p.isMobile ? (
        ""
      ) : (
        <InfoImage
          key={"infoImage"}
          image={p.furtherInfo.image}
          about="InfoBannerImage"
        />
      )}
      <InfoBanner>
        <StyledTitle
          key={"title"}
          level={4}
          size={media.mobile || media.tiny ? 5 : 4}
        >
          {headline}
        </StyledTitle>
        <StyledText>{text}</StyledText>
        <ButtonContainer>
          <StyledCTA
            key="cta1"
            href={"_url" in links[0].ctaRef ? links[0].ctaRef._url : ""}
            aria-label={links[0].ctaLabel + ", " + headline}
          >
            {links[0].ctaLabel}
          </StyledCTA>
          <StyledCTA
            key="cta2"
            href={"_url" in links[1].ctaRef ? links[1].ctaRef._url : ""}
            aria-label={links[1].ctaLabel + ", " + headline}
          >
            {links[1].ctaLabel}
          </StyledCTA>
        </ButtonContainer>
      </InfoBanner>
    </BannerContainer>
  );
}

FurtherInfoBanner.getImages = ({ image }: FurtherInfoType) => {
  return image ? [image] : [];
};
