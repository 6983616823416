import React from "react";
import Collapsible from "../common/Collapsible";
import DefaultHeadline from "../common/Headline";
import SectionList, { SectionTypes } from "./SectionList";
import styled from "styled-components";
import { withSection } from "./Section";

const Headline = styled(DefaultHeadline)`
  margin: 0;
`;

interface Props {
  items: {
    label: string;
    content: SectionTypes[];
  }[];
  id: string;
}
const AccordionComponent = (props: Props) => (
  <>
    {props.items.map((e, idx) => (
      <Collapsible
        key={idx}
        label={
          <Headline size={4} level={2}>
            {e.label}
          </Headline>
        }
        ariaLevel={2}
        uuid={`s_${props.id}`}
      >
        <SectionList
          id={`s_${props.id}`}
          sections={e.content}
          sectionProps={{
            noBackground: true,
            fullWidth: true,
            reducedSectionPadding: true
          }}
        />
      </Collapsible>
    ))}
  </>
);

AccordionComponent.getImages = (props: Props) => {
  let images = [];
  props.items.forEach(i => {
    images = images.concat(SectionList.getImages({ sections: i.content }));
  });

  return images;
};

const AccordionSection = withSection<Props>(
  AccordionComponent,
  null,
  props => !props.items || !props.items.length
);
export default AccordionSection;
