import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import {
  font,
  media,
  bottomMargins,
  spacing,
  colors,
  fontSizing
} from "../theme";
import { Chevron } from "../common/icons";
import Content, { DEFAULT_PADDING_MOBILE_ZOOM } from "../common/Content";
import Headline from "../common/Headline";
import GlobalContext from "../context/GlobalContext";
import Filter, { FilterOption } from "../common/Filter";
import CategoryClusterSlider from "../categoryCluster/CategoryClusterSlider";
import { withSection } from "./Section";
import { Image } from "../../lib/image";

interface ContainerProps {
  bgImage: Image;
  open: boolean;
}
const Container = styled.header<ContainerProps>`
  position: relative;
  height: auto;
  color: white;
  transition: all 170ms ease-in-out;
  ${(p: ContainerProps) =>
    p.open &&
    css`
      min-height: 0;
      ${bottomMargins.medium}
    `}
  @media print {
    display: none;
  }
  @media screen and ${media.mobile} {
    padding-top: 32px;
  }
`;

const StyledHeadline = styled(Headline)<{ withBottomMargin?: boolean }>`
  text-align: center;
  ${fontSizing("headline", 60)}
  @media screen and ${media.mobile} {
    font-weight: 390;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
`;

const HeadlineStatement = styled.span<{ open?: boolean }>`
  display: block;
  width: 100%;
  color: ${colors.calypso};
  font-weight: 420;
  font-size: 20px;
  line-height: 40px;
  font-weight: 420;

  @media screen and ${media.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const HeadlineQuestion = styled.span<{ open?: boolean }>`
  display: block;
  width: 100%;
  font-weight: ${font.weightLight};
  color: ${colors.blueDark};
  font-size: 36px;
  line-height: 72px;

  @media screen and ${media.mobile} {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    font-weight: 390;
    font-color: ${colors.blue};
  }
`;

const StyledContentContainer = styled(Content)`
  @media screen and ${media.superTinyZoom125} {
    padding: 0 ${DEFAULT_PADDING_MOBILE_ZOOM};
  }
`;

const StyledChevron = styled(Chevron)`
  display: inline-block;
  margin-top: calc(4px * 3);
`;
interface Props {
  headline: string;
  subline: string;
  image: Image;
  inline?: boolean;
}

function Finder(props: Props) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const { segments } = useContext(GlobalContext);
  const setEmptyType = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
    setType("");
  };

  const handleSelect = (option: FilterOption) => {
    const clear = type === option.id;
    setOpen(clear ? false : true);
    setType(clear ? "" : option.id);
  };

  const { image, headline, subline, inline } = props;

  const filterOptions = segments.items.map(segment => ({
    label: segment.name,
    id: segment.slug
  }));

  const selectedSegment = type && segments.items.find(seg => seg.slug === type);

  return (
    <>
      <Container open={open} bgImage={image}>
        <StyledContentContainer padded={inline ? undefined : true} center>
          <StyledHeadline size={1} level={1} white withBottomMargin={open}>
            <HeadlineStatement open={open}>{headline}</HeadlineStatement>
            <HeadlineQuestion open={open}>{subline}</HeadlineQuestion>
          </StyledHeadline>
          <Filter
            label="Sie sind"
            options={filterOptions}
            onSelect={handleSelect}
            onClearFilter={setEmptyType}
            selected={type}
            ariaButtonPrefix="Auswahl Kundengruppe: "
          />
        </StyledContentContainer>
      </Container>
      {open && selectedSegment && selectedSegment.categoryClusters && (
        <Content padded bottomMargin>
          <CategoryClusterSlider
            segment={selectedSegment}
            categoryClusters={selectedSegment.categoryClusters}
          />
        </Content>
      )}
    </>
  );
}

Finder.getImages = ({ image }: Props) => {
  if (!image) return [];
  return [image];
};
const FinderSection = withSection<Props>(Finder, () => ({
  fullWidth: true,
  noTopPadding: true,
  noBottomPadding: true
}));

export default FinderSection;
