import React, { ReactNode } from "react";
import { withSection } from "./Section";

interface Props {
  element: ReactNode | ReactNode[];
}
function Custom({ element }: Props) {
  return <>{element}</>;
}
const CustomSection = withSection<Props>(Custom);

export default CustomSection;
