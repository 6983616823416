import React from "react";
import DefaultRichText from "../common/RichText";
import { withSection } from "./Section";
import styled from "styled-components";

export interface Props {
  text: string;
}
const StyledRichText = styled(DefaultRichText)`
  max-width: 920px;
`;
function RichText({ text }: Props) {
  return <StyledRichText html={text} />;
}
const RichTextSection = withSection(RichText, () => ({
  noTopPadding: true,
  noBottomPadding: true,
  contentBlock: true
}));

export default RichTextSection;
