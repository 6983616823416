import React from "react";
import styled from "styled-components";
import { colors, font, fontSizing, media, THEME_SCALE } from "../theme";
import Card from "../common/Card";
import Button from "../common/Button";
import Headline from "../common/Headline";
import EventFacts from "./EventFacts";
import Spacer from "../common/Spacer";
import Link from "../utils/Link";

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.blue};
  padding: calc((4px * 12) * ${THEME_SCALE});

  & > div:first-child {
    flex: 1;
  }

  @media screen and ${media.superTinyZoom125} {
    padding: calc((4px * 12) * ${THEME_SCALE} / 4);
  }
`;

const Title = styled(Headline)`
  ${fontSizing("headline", 32)}
  line-height: 40px;
  font-weight: ${font.weightMedium};
  margin-bottom: calc(4px * 6);
`;

export const Paragraph = styled.div`
  ${fontSizing("label", 20)}
  margin-bottom: calc(4px * 3);
  color: inherit;
  font-weight: ${font.weightMedium};
`;

export interface Props {
  dates: { date: string; time: string }[];
  name: string;
  description: string;
  organizer?: string;
  location?: string;
  ctaHref: string;
}

const EventTile = (p: Props) => {
  return (
    <Container white smallShadow>
      <Title level={3}>
        <Link href={p.ctaHref}>
          <a tabIndex={-1}>{p.name}</a>
        </Link>
      </Title>
      <Paragraph>{p.description}</Paragraph>
      <Spacer />
      <EventFacts
        dates={p.dates}
        organizer={p.organizer}
        location={p.location}
      />
      <Button small ghost href={p.ctaHref}>
        Zur Veranstaltung
      </Button>
    </Container>
  );
};

export default EventTile;
