import React from "react";
import {
  ContentRef,
  ExternalProgram,
  Program,
  ProgramType
} from "../../lib/CmsApi";
import Label from "../common/Label";
import Quote from "../common/Quote";
import Content from "../common/Content";
import Slider from "../Slider";
import { RouteName } from "../../lib/routes";
import SimpleInformationCard from "../common/SimpleInformationCard";
import styled from "styled-components";
import { media, colors } from "../theme";
import { withSection } from "./Section";

const StyledLabel = styled(Label)`
  color: ${colors.blue};
`;
interface ItemProps {
  title: string;
  quote: string;
  subline: string;
  program: ContentRef & (Program | ExternalProgram) & { type: ProgramType };
}
const VoiceItem = styled(Content)`
  align-items: flex-start;
  @media screen and ${media.mobile} {
    flex-direction: column;
  }
`;
const QuoteBox = styled.div`
  /* Fix IE-11 word wrap issue*/
  max-width: 100%;
  padding-right: calc(4px * 5);
  @media screen and ${media.tablet} {
    padding-right: calc(4px * 4);
  }
  @media screen and ${media.tiny} {
    padding-right: 0;
    padding-bottom: calc(4px * 10);
  }
`;
const StyledSimpleInformationCard = styled(SimpleInformationCard)`
  max-width: 320px;
  @media screen and ${media.desktop} {
    min-width: 280px;
  }
`;
const CustomerVoiceItem = (p: ItemProps) => {
  const linkItems =
    "ctaHref" in p.program
      ? { href: p.program.ctaHref }
      : {
          route: "shortProgram" as RouteName,
          params: {
            slug: p.program.slug
          }
        };
  return (
    <VoiceItem row>
      <QuoteBox>
        <Label big>{p.title}</Label>
        <Quote author={p.subline}>„{p.quote}“</Quote>
      </QuoteBox>
      {p.program.name && (
        <div>
          <StyledLabel>Gewähltes Programm</StyledLabel>
          <StyledSimpleInformationCard
            label={p.program.type && p.program.type.name}
            text={p.program.name}
            ariaLabel={"Zugehöriges Programm, " + p.program.name}
            {...linkItems}
          />
        </div>
      )}
    </VoiceItem>
  );
};

interface Props {
  title: string;
  voices: Omit<ItemProps, "title">[];
  inline?: boolean;
}
const CustomerVoicesComponent = (p: Props) => (
  <Slider
    itemCountXLarge={1}
    itemCountLarge={1}
    itemCountMedium={1}
    itemCountSmall={1}
    ariaLabel={p.title}
    padded
  >
    {p.voices.map((voice, i) => {
      return <CustomerVoiceItem key={i} title={p.title} {...voice} />;
    })}
  </Slider>
);

const CustomerVoicesSection = withSection<Props>(
  CustomerVoicesComponent,
  null,
  p => !p.voices || !p.voices.length
);
export default CustomerVoicesSection;
