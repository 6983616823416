import React, { useCallback, useEffect, useState } from "react";
import { Image as ImageType } from "../../../lib/image";
import { ContentRef } from "../../../lib/CmsApi";
import styled, { css } from "styled-components";
import CrossteaserArrow from "./CrossteaserArrow";
import CrossTeaser from "./Crossteaser";
import { breakpoints, colors, font, media } from "../../theme";
import { MobileSlider } from "./MobileSlider";
import useMediaQuery from "../../../lib/useMediaQuery";

export type ReportProps = {
  mainTitle: string;
  mainCard: MainCard;
  cardRowOne: CardRow;
  cardRowTwo: CardRow;
  cardRowThree: CardRow;
  furtherDocuments: string;
  lowerTextArea: LowerTextArea;
};

export type MainCard = {
  headline?: string;
};

type CardImage = {
  image: ImageType;
};

export type CardRow = {
  headline?: string;
  cardTitle?: string;
  image: CardImage;
  buttonBeschriftung: string;
  link: ContentRef;
}[];

export type LowerTextArea = {
  text: string;
  ctaLabel: string;
  ctaRef: ContentRef;
};

export type DocumentLink = {
  _content: string;
  _id: number;
  _ref: string;
};

const MainContainer = styled.div`
  max-width: 1300px;

  padding-top: 48px;
  padding-bottom: 90px;
`;

const MobileViewContainer = styled.div`
  height: auto;
  max-height: auto;
  padding-bottom: 80px;
`;

const MainTitle = styled.h1`
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */

  /* Primär/IFB Blau */

  color: #004669;
`;

const UpperGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 32px;
  margin-bottom: 32px;
  min-width: 0px;
  row-gap: 32px;

  @media screen and ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    width: auto;
  }

  @media screen and ${media.desktop} {
    column-gap: 30px;
  }
`;

const MidGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 32px;
  margin-bottom: 20px;
  min-width: 0px;
`;

const LowerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 32px;
  min-width: 0px;

  @media screen and ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    width: auto;
    row-gap: 15px;
  }

  @media screen and (max-width: ${breakpoints.wide}px) {
    column-gap: 15px;
  }

  @media screen and ${media.mobile} {
    row-gap: 20px;
  }
`;

const CardRowTop = styled.div`
  grid-column: span 1;

  // @media screen and ${media.tablet} {
  //   grid-column: span 1;
  // }
`;

const CardRowMid = styled.div`
  grid-column: span 3;
`;

const CardRowLow = styled.div`
  grid-column: span 1;
`;

const FurtherDocuments = styled.h4`
  font-family: Brandon Text;
  font-size: 24px;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.blueDark};
  margin-bottom: 16px;
`;

const LowerBanner = styled.div`
  width: 100%;
  height: 56px;

  @media screen and ${media.tiny} {
    margin-bottom: 40px;
  }
`;

const LowerBannerText = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;

  @media screen and ${media.tablet} {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    line-height: 15px;
    font-size: 14px;
  }

  @media screen and ${media.mobile} {
    margin-bottom: 40px;
  }

  @media screen and ${media.tiny} {
    margin-bottom: 55px;
  }
`;

const ctaStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  gap: 10px;

  width: 127px;
  height: 48px;

  background: #004669;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #004669;
  background-color: #ffffff;

  border: 2px solid #004669;
  /* Shadow Outer Blue */

  // filter: drop-shadow(0px 20px 40px rgba(0, 69, 106, 0.2));
  border-radius: 4px;

  white-space: nowrap;
  margin-top: 10px;

  overflow: visible;
  padding: 2px 5px 2px 5px;
  margin-right: 10px;
  text-align: center;
  line-height: 40px;
`;
const StyledCTA = styled.a`
  ${ctaStyle};
  text-decoration: none;
  margin-top: 16px;

  &:hover {
    color: #3c6c92;
    border: 2px solid #3c6c92;
  }
`;

export default function ReportsComponent(p: ReportProps) {
  const isMobile = useMediaQuery(breakpoints.tiny);

  const { cardRowOne, cardRowTwo, cardRowThree } = p;

  const mobileCardsBericht = cardRowOne.concat(cardRowTwo);

  return isMobile ? (
    <MobileViewContainer>
      <MobileSlider headline={p.mainTitle} cards={mobileCardsBericht} />
      <CrossteaserArrow
        id={p.mainCard.headline}
        title={p.mainCard[0].headline}
      />
      <MobileSlider
        headline={"Weitere nachhaltigkeitsrelevante Dokumente"}
        cards={cardRowThree}
      />

      <LowerBanner>
        <LowerBannerText
          dangerouslySetInnerHTML={{
            __html: p.lowerTextArea[0].text
          }}
        />
        <StyledCTA
          href={
            p.lowerTextArea[0].ctaRef._url ? p.lowerTextArea[0].ctaRef._url : ""
          }
        >
          {p.lowerTextArea[0].ctaLabel
            ? p.lowerTextArea[0].ctaLabel
            : "Zum Archiv"}
        </StyledCTA>
      </LowerBanner>
    </MobileViewContainer>
  ) : (
    <MainContainer>
      <MainTitle>{p.mainTitle}</MainTitle>
      <UpperGrid>
        <CardRowTop>
          <CrossteaserArrow
            id={p.mainCard[0].headline}
            title={p.mainCard[0].headline}
          />
        </CardRowTop>
        {cardRowOne.map((card, idx) => (
          <CardRowTop key={"cardRowOne_" + idx}>
            <CrossTeaser
              cardSize={"md"}
              key={idx + "rowOne"}
              id={idx + card.headline}
              headline={card.headline}
              image={card.image.image}
              buttonTitle={card.buttonBeschriftung}
              link={card.link}
            />
          </CardRowTop>
        ))}
      </UpperGrid>
      <MidGrid>
        {cardRowTwo.map((card, idx) => (
          <CardRowMid key={"cardRowTwo_" + idx}>
            <CrossTeaser
              cardSize={"lg"}
              key={idx + "rowTwo"}
              id={idx + card.headline}
              headline={card.headline}
              image={card.image.image}
              buttonTitle={card.buttonBeschriftung}
              link={card.link}
            />
          </CardRowMid>
        ))}
      </MidGrid>
      <FurtherDocuments>
        {p.furtherDocuments
          ? p.furtherDocuments
          : "Weitere nachhaltigkeitsrelevante Dokumente"}
      </FurtherDocuments>
      <LowerGrid>
        {cardRowThree.map((card, idx) => (
          <CardRowLow key={"cardRowThree_" + idx}>
            <CrossTeaser
              cardSize={"sm"}
              key={idx + "rowThree"}
              id={idx + card.headline}
              headline={card.headline}
              image={card.image.image}
              buttonTitle={card.buttonBeschriftung}
              link={card.link}
            />
          </CardRowLow>
        ))}
      </LowerGrid>
      <LowerBanner>
        <LowerBannerText
          dangerouslySetInnerHTML={{
            __html: p.lowerTextArea[0].text
          }}
        />
        <StyledCTA
          href={
            p.lowerTextArea[0].ctaRef._url ? p.lowerTextArea[0].ctaRef._url : ""
          }
        >
          Zum Archiv
        </StyledCTA>
      </LowerBanner>
    </MainContainer>
  );
}

ReportsComponent.getImages = (props: ReportProps) => {
  let images = [];

  props.cardRowOne.map(el => (images = images.concat(el.image.image)));
  props.cardRowTwo.map(el => (images = images.concat(el.image.image)));
  props.cardRowThree.map(el => (images = images.concat(el.image.image)));

  return images;
};
