import {
  SustainabilityGoalsComponent,
  SDGProps
} from "../common/SustainabilityGoalsComponent";
import { withSection } from "./Section";

const DevelopmentGoals = withSection<SDGProps>(
  SustainabilityGoalsComponent,
  null
);
export default DevelopmentGoals;
