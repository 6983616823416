import { withSection } from "./Section";
import ImageTextTeaserComponent, {
  ImageTextTeaserProps
} from "../common/ImageTextTeaserComponent";

const ImageTextTeaser = ({
  swapLayout,
  ...props
}: ImageTextTeaserProps & { swapLayout?: boolean }) => {
  return (
    <ImageTextTeaserComponent
      {...props}
      swap={swapLayout}
    ></ImageTextTeaserComponent>
  );
};

ImageTextTeaser.getImages = (props: ImageTextTeaserProps) => {
  return ImageTextTeaserComponent.getImages(props);
};

const ImageTextTeaserSection = withSection<ImageTextTeaserProps>(
  ImageTextTeaser,
  () => ({
    noTopPadding: true
  })
);
export default ImageTextTeaserSection;
