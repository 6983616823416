import {
  BannerTeaserComponent,
  BannerTeaserProps
} from "../common/BannerTeaserComnponent";
import { withSection } from "./Section";

const BannerTeaserSection = withSection<BannerTeaserProps>(
  BannerTeaserComponent,
  null
);
export default BannerTeaserSection;
