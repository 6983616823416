import React from "react";
import Paragraph from "../../common/Paragraph";
import Headline from "../../common/Headline";
import DefaultLabel from "../../common/Label";
import Button from "../../common/Button";
import { FlexColumn } from "../../common/Flex";
import { ContentRef } from "../../../lib/CmsApi";
import styled from "styled-components";
import { colors } from "../../theme";

const Label = styled(DefaultLabel)`
  color: ${colors.darkgrey};
`;

const StyledButton = styled(Button)`
  max-width: 100%;
`;
export interface Props {
  label: string;
  headline: string;
  text: string;
  ctaRef?: ContentRef;
  ctaLabel?: string;
}
const ShowcaseItem = (p: Props) => (
  <FlexColumn alignItems="flex-start" role="listitem">
    <Label small>{p.label}</Label>
    <Headline size={4} bold level={3}>
      {p.headline}
    </Headline>
    <Paragraph small blue>
      {p.text}
    </Paragraph>
    {p.ctaRef && p.ctaLabel && (
      <StyledButton small ghost href={p.ctaRef._url}>
        {p.ctaLabel}
      </StyledButton>
    )}
  </FlexColumn>
);

export default ShowcaseItem;
