import React from "react";
import styled, { css } from "styled-components";
import Headline from "../Headline";
import { Image as ImageType } from "../../../lib/image";
import { ContentRef, Media, MediaRef } from "../../../lib/CmsApi";
import Slider from "../../Slider";
import { colors, media } from "../../theme";
import Content from "../Content";

interface Props {
  headline: string;
  cards: CardProps[];
}

interface CardProps {
  headline?: string;
  image?: CardImage;
  buttonBeschriftung: string;
  link: ContentRef;
}

type CardImage = {
  image: ImageType;
};

const Container = styled.div`
  padding-top: 32px;
  height: auto;
`;

const MainTitle = styled(Headline)`
  padding: 5px;

  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 28px;
  line-height: 36px;

  color: #004669;
`;

const SlideContainer = styled(Content as any)<{ backgroundImage?: boolean }>``;
const DescBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 80px;
`;

const HeadingButtonContainer = styled.div`
  padding-left: 5px;
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 24px;
`;

const DescBoxHeading = styled.h1`
  color: ${colors.blueDark};
  width: 298px;
  height: 100%;
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 18px;
  line-height: 30px;
`;

const CardImage = styled.div<{ image: ImageType }>`
  height: 232px;
  width: 345px;
  background: url(${p => p.image._src}) no-repeat center center;
  background-size: cover;
`;

const ctaStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  width: fit-content;
  height: 48px;

  background: #004669;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #004669;
  background-color: #ffffff;

  border: 2px solid #004669;
  /* Shadow Outer Blue */

  border-radius: 4px;

  white-space: nowrap;
  margin-top: 10px;

  overflow: visible;
  padding: 2px 5px 2px 5px;
  margin-right: 10px;
  text-align: center;
  line-height: 40px;
`;
const StyledCTA = styled.a`
  ${ctaStyle};
  margin-top: 16px;
  margin-left: 10px;
  text-decoration: none;
  &:hover {
    color: #3c6c92;
    border: 2px solid #3c6c92;
  }
`;

const MobileSliderItem = (p: CardProps) => {
  return (
    <SlideContainer row>
      <DescBoxContainer>
        <CardImage image={p.image.image} />
        <HeadingButtonContainer>
          <DescBoxHeading>{p.headline}</DescBoxHeading>
          <StyledCTA
            key="document_link"
            href={p.link._id ? `/api/services/document/${p.link._id}` : ""}
            target="_blank"
            tabIndex={0}
          >
            {p.buttonBeschriftung}
          </StyledCTA>
        </HeadingButtonContainer>
      </DescBoxContainer>
    </SlideContainer>
  );
};

export function MobileSlider(p: Props) {
  return (
    <>
      <Container>
        <MainTitle size={4} bold grey level={2}>
          {p.headline}
        </MainTitle>
        <Slider
          showArrowControls={false}
          borderedOverlayButtons={true}
          itemCountSmall={1}
          ariaLabel={p.headline}
          innerButtons
          alwaysMobileControls={false}
          hideArrowsOnMobile={true}
          heroSlider={true}
        >
          {p.cards.map((s, idx) => (
            <MobileSliderItem
              image={s.image}
              key={idx}
              headline={s.headline}
              buttonBeschriftung={s.buttonBeschriftung}
              link={s.link}
              {...s}
            />
          ))}
        </Slider>
      </Container>
    </>
  );
}

// export const MobileSliderSection = withSection(MobileSlider, () => ({
//   fullWidth: true,
//   color: "white",
//   noTopPadding: true,
//   noBottomPadding: true
// }));
