import React, { useContext } from "react";
import Paragraph from "../common/Paragraph";
import TrackingContext from "../context/TrackingContext";
import { withSection } from "./Section";
import { Checkbox, CheckboxLabel } from "../common/Checkbox";

interface Labels {
  description: string;
  checkboxLabel: string;
}
interface Props {
  enabled: Labels;
  disabled: Labels;
}

function DataPrivacy(p: Props) {
  const tracking = useContext(TrackingContext);

  const labels = tracking.isEnabled ? p.enabled : p.disabled;
  return (
    <>
      <>
        <Paragraph serif>{labels.description}</Paragraph>
        <span>
          <Checkbox
            type="checkbox"
            id="trackingCheckbox"
            checked={tracking.isEnabled}
            aria-checked={tracking.isEnabled}
            onChange={() => {
              if (tracking.isEnabled) {
                tracking.disallowTracking();
              } else {
                tracking.allowTracking();
              }
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                if (tracking.isEnabled) {
                  tracking.disallowTracking();
                } else {
                  tracking.allowTracking();
                }
              }
            }}
          />
          <CheckboxLabel htmlFor={"trackingCheckbox"} aria-hidden={true}>
            {labels.checkboxLabel}
          </CheckboxLabel>
        </span>
      </>
    </>
  );
}
const DataPrivacySection = withSection<Props>(DataPrivacy);

export default DataPrivacySection;
