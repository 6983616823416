import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import elements from "./index";
import {
  Contact,
  ContentRef,
  Document,
  ExternalEvent,
  ExternalProgram,
  JobOffer,
  MagazinNews,
  MagazinPortraits,
  Media,
  MediaRef,
  Program,
  TextTeaser
} from "../../lib/CmsApi";
import { RouteName, RouteParams } from "../../lib/routes";
import { breakpoints, maxWidth, spacing, media } from "../theme";
import { DEFAULT_PADDING } from "../common/Content";
import { SectionProps } from "./Section";

export type SectionTypes =
  | {
      element: ReactNode | ReactNode[];
      _type: "Custom";
    }
  | {
      headline: string;
      entries: {
        headline: string;
        overline?: string;
        image: string | Media & MediaRef;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef | { route: RouteName; params: RouteParams };
      }[];
      _type: "Checkerboard";
    }
  | {
      text?: string;
      _type: "Text";
    }
  | {
      table: string;
      seperator: "," | ";";
      striped: "-" | "vertikal" | "horizontal";
      _type: "Table";
    }
  | {
      headline: string;
      copy?: string;
      contacts: {
        role: string;
        contact: ContentRef & Contact;
      }[];
      _type: "Contacts";
    }
  | {
      image: MediaRef & Media;
      _type: "Image";
    }
  | {
      table?: string;
      _type: "Table";
    }
  | {
      headline?: string;
      documents?: (ContentRef & Document)[];
      _type: "Downloads";
    }
  | {
      headline: string;
      intro?: string;
      cases?: {
        label: string;
        headline: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
      }[];
      _type: "ShowCase";
    }
  | {
      headline: string;
      overline?: string;
      subline?: string;
      text: string;
      ctaLabel?: string;
      ctaRef?: ContentRef;
      layoutType?: boolean;
      _type: "TextTeaser";
    }
  | {
      teaser: ContentRef & TextTeaser;
      _type: "LinkTextTeaser";
    }
  | {
      items?: (ContentRef & MagazinPortraits)[];
      _type: "FundingStories";
    }
  | {
      headline: string;
      overline?: string;
      image: MediaRef & Media;
      text: string;
      ctaLabel?: string;
      ctaRef?: ContentRef;
      layoutType?: boolean;
      _type: "ImageTextTeaser";
    }
  | {
      items?: {
        label?: string;
        content?: (
          | {
              text?: string;
              _type: "Text";
            }
          | {
              headline?: string;
              documents?: (ContentRef & Document)[];
              _type: "Downloads";
            }
          | {
              image: MediaRef & Media;
              _type: "Image";
            }
          | {
              table?: string;
              _type: "Table";
            })[];
      }[];
      _type: "Accordion";
    }
  | {
      doNotTrack?: string;
      track?: string;
      trackCheckbox?: string;
      _type: "DataPrivacy";
    }
  | {
      program: ContentRef;
      _type: "ProgramTeaser";
    }
  | {
      overline: string;
      headline: string;
      intro: string;
      ctaRef: ContentRef;
      ctaLabel: string;
      dias: {
        headline: string;
        copy?: string;
        icon?: MediaRef & Media;
      }[];
      _type: "DiaShow";
    }
  | {
      title: string;
      voices: {
        quote: string;
        subline: string;
        program: ContentRef & (Program | ExternalProgram);
      }[];
      _type: "CustomerVoices";
    }
  | {
      sliderItems: {
        title: string;
        description: string;
        callToActionLink: ContentRef;
        image: MediaRef & Media;
        disable?: boolean;
      }[];
      _type: "HeroSlider";
    }
  | {
      title: string;
      jobs: (ContentRef & JobOffer)[];
      small?: boolean;
      _type: "Jobs";
    }
  | {
      overline: string;
      headline: string;
      text: string;
      ctaLabel: string;
      _type: "Newsletter";
    }
  | {
      headline: string;
      subline: string;
      image?: MediaRef & Media;
      _type: "Finder";
    }
  | {
      headline: string;
      overline?: string;
      subline?: string;
      text: string;
      collapseLabel?: string;
      _type: "CollapsibleText";
    }
  | {
      news?: MagazinNews[];
      events?: {
        headline: string;
        intro?: string;
        items?: ExternalEvent[];
      };
      stories?: MagazinPortraits[];
      _type: "Magazin";
    };

export function getElement(
  type
): typeof elements[keyof typeof elements] | null {
  const Component = elements[type];

  if (!Component) {
    if (process.env.NODE_ENV === "development") {
      console.error(`Unknown section type ${type}`);
    }
    return null;
  }
  return Component;
}

export function getElementAndData(
  { _type, ...data },
  key
): {
  key: string;
  data: Omit<SectionWithId, "_type">;
  Element: typeof elements[keyof typeof elements];
  _type: string;
} {
  const Element = getElement(_type);
  return Element && { Element, data, key, _type };
}

const SectionListStyle = styled.div``;
export const Sidebar = styled.aside`
  ${spacing("padding-left", 24)}
  ${spacing("padding-bottom", 24)}
  width: 320px;
  @media screen and ${media.tablet} {
    width: 240px;
  }
  @media screen and ${media.mobile} {
    width: 240px;
  }
  @media screen and ${media.tiny} {
    width: 100%;
    align-items: flex-start;
  }
  display: flex;
  float: right;
  flex-direction: column;
  align-items: flex-end;
  margin-right: ${DEFAULT_PADDING};
  @media (min-width: ${breakpoints.wide}px) {
    margin-right: calc(${DEFAULT_PADDING} + 50% - (${breakpoints.wide}px / 2));
  }
  @media (min-width: ${maxWidth.page + 81}px) {
    margin-right: calc(50% - (${breakpoints.wide}px / 2));
  }
`;
export type SectionWithId = SectionTypes & {
  id?: string;
};
interface Props {
  sections: SectionWithId[];
  sectionProps?: SectionProps;
  className?: string;
  sidebar?: () => ReactElement;
  main?: boolean;
  id?: string;
}
export default function SectionList({
  className,
  sectionProps,
  sidebar,
  sections: dirtySections,
  main,
  id: sectionId = ""
}: Props) {
  const sections = dirtySections.filter(Boolean);
  if (!sections) return null;
  const sec = sections.map(getElementAndData).filter(Boolean);
  const Tag = main ? "main" : React.Fragment;
  return (
    <SectionListStyle className={className}>
      {sidebar && <Sidebar>{sidebar()}</Sidebar>}
      <Tag>
        {sidebar && <div />}
        {sec.map(({ Element: Section, data, key, _type }) => {
          const clearBoth = !["Text", "Table"].includes(_type);
          const { id, ...componentProps } = data;
          return (
            <Section
              key={key}
              id={sectionId + key}
              sectionProps={{
                ...sectionProps,
                id,
                clearBoth,
                inSectionList: true
              }}
              {...componentProps}
            />
          );
        })}
      </Tag>
    </SectionListStyle>
  );
}

SectionList.getImages = (props: { sections: SectionWithId[] }) => {
  let images = [];
  if (!props.sections) return images;
  const sec = props.sections.map(getElementAndData).filter(Boolean);
  sec.forEach(s => {
    if (typeof s.Element.getImages === "function") {
      const elImages = s.Element.getImages(s.data);
      if (!elImages) return;

      images = images.concat(elImages);
    }
  });

  return images;
};
