import { TopicItem } from "./TopicGrid";
import { Calendar, PartTimeClock, SuitCase } from "./icons";
import React from "react";

import styled from "styled-components";
import { fontSizing, spacing, colors } from "../theme";
import { HeadingLevel } from "./Headline";

//TODO: Import Joblist and JobEntry from CareerJobOfferPage
const JobList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const JobEntry = styled.li`
  display: block;
  font-size: 0.8em;
  line-height: 1.6;
  margin: 0;
  ${spacing("margin-bottom", 16)}

  color:#fff;
  > svg {
    width: 1em;
    height: 1em;
    margin-right: 1ch;
  }
`;
export const calculateDay = (date: Date): number => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return Math.floor(diff / (1000 * 3600 * 24));
};

const StyledJobList = styled(JobList)`
  ${spacing("margin-bottom", 8)}
`;
const StyledJobEntry = styled(JobEntry)`
  ${fontSizing("label", 20)}
  color: ${colors.blue};
`;
const JobTile = styled(TopicItem)`
  width: 100%;
`;

export default function JobOfferTile(job: {
  slug: string;
  name: string;
  intro: string;
  employmentType?: string;
  employmentSchema?: string;
  _url?: string;
  category: {
    slug: string;
    name: string;
  };
  alwaysShowIntro?: boolean;
  small?: boolean;
  headlineLevel?: HeadingLevel;
}) {
  return (
    <JobTile
      key={job.slug}
      type={job.category.name}
      headline={job.name}
      headlineLevel={job.headlineLevel}
      text={job.small ? undefined : job.intro}
      cta="Mehr erfahren"
      link={{
        route: "joboffer",
        params: {
          level: job.category.slug,
          slug: job.slug
        }
      }}
      introOnlyOnWide={!job.alwaysShowIntro}
    >
      {!job.small && (
        <StyledJobList>
          {job.employmentType && (
            <StyledJobEntry>
              <SuitCase />
              <span>{job.employmentType}</span>
            </StyledJobEntry>
          )}
          {job.employmentSchema && (
            <StyledJobEntry>
              <PartTimeClock />
              <span>{job.employmentSchema}</span>
            </StyledJobEntry>
          )}
        </StyledJobList>
      )}
    </JobTile>
  );
}
