import React, { useState } from "react";
import styled from "styled-components";
import {
  SDG1,
  SDG1Clicked,
  SDG1Info,
  SDG7,
  SDG7Clicked,
  SDG7Info,
  SDG8,
  SDG8Clicked,
  SDG8Info,
  SDG9,
  SDG9Clicked,
  SDG9Info,
  SDG10,
  SDG10Clicked,
  SDG10Info,
  SDG11,
  SDG11Clicked,
  SDG11Info,
  SDG13,
  SDG13Clicked,
  SDG13Info
} from "./icons";
import { media } from "../theme";

const MobileGraphicContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and ${media.tablet} {
    align-items: center;
    justify-content: center;
  }
`;

const UpperCard = styled.div<{ height?: string }>`
  width: 345px;
  height: ${props => (props.height ? props.height : "80px")};
`;
const LowerCard = styled.div<{ open?: boolean; height?: string }>`
  width: 345px;
  height: ${props => (props.open ? props.height : "0px")};
  transition: all 0.4s;
  overflow: hidden;
  margin-bottom: 10px;
`;

export function MobileInfoGraphic() {
  const [currentSDG, setSDG] = useState("");

  function handleClick(tile: string) {
    if (!currentSDG) {
      setSDG(tile);
    }
    if (currentSDG !== tile) {
      setSDG(tile);
    } else {
      setSDG("");
    }
  }
  return (
    <MobileGraphicContainer>
      <UpperCard height={"53.81px"} onClick={() => handleClick("sdg1")}>
        {currentSDG === "sdg1" ? <SDG1Clicked /> : <SDG1 />}
      </UpperCard>
      <LowerCard height={"66px"} open={currentSDG === "sdg1" ? true : false}>
        {<SDG1Info />}
      </LowerCard>

      <UpperCard height={"80.81px"} onClick={() => handleClick("sdg7")}>
        {currentSDG === "sdg7" ? <SDG7Clicked /> : <SDG7 />}
      </UpperCard>
      <LowerCard height={"84px"} open={currentSDG === "sdg7" ? true : false}>
        {<SDG7Info />}
      </LowerCard>

      <UpperCard height={"82.95px"} onClick={() => handleClick("sdg8")}>
        {currentSDG === "sdg8" ? <SDG8Clicked /> : <SDG8 />}
      </UpperCard>
      <LowerCard height={"114px"} open={currentSDG === "sdg8" ? true : false}>
        {<SDG8Info />}
      </LowerCard>

      <UpperCard height={"80.55px"} onClick={() => handleClick("sdg9")}>
        {currentSDG === "sdg9" ? <SDG9Clicked /> : <SDG9 />}
      </UpperCard>
      <LowerCard height={"114px"} open={currentSDG === "sdg9" ? true : false}>
        {<SDG9Info />}
      </LowerCard>

      <UpperCard height={"79.96px"} onClick={() => handleClick("sdg10")}>
        {currentSDG === "sdg10" ? <SDG10Clicked /> : <SDG10 />}
      </UpperCard>
      <LowerCard height={"84px"} open={currentSDG === "sdg10" ? true : false}>
        {<SDG10Info />}
      </LowerCard>

      <UpperCard height={"64.45px"} onClick={() => handleClick("sdg11")}>
        {currentSDG === "sdg11" ? <SDG11Clicked /> : <SDG11 />}
      </UpperCard>
      <LowerCard height={"114px"} open={currentSDG === "sdg11" ? true : false}>
        {<SDG11Info />}
      </LowerCard>

      <UpperCard height={"56px"} onClick={() => handleClick("sdg13")}>
        {currentSDG === "sdg13" ? <SDG13Clicked /> : <SDG13 />}
      </UpperCard>
      <LowerCard height={"84px"} open={currentSDG === "sdg13" ? true : false}>
        {<SDG13Info />}
      </LowerCard>
    </MobileGraphicContainer>
  );
}
