import TextTeaserComponent, {
  TextTeaserProps
} from "../common/TextTeaserComponent";
import { withSection } from "./Section";

const CollapsibleTextComponent = props => (
  <TextTeaserComponent {...props} layoutType collapseStyle />
);
const CollapsibleTextSection = withSection<TextTeaserProps>(
  CollapsibleTextComponent
);

export default CollapsibleTextSection;
