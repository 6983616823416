import React from "react";
import styled from "styled-components";
import RichText from "./RichText";
import { media } from "../theme";

export type BannerTeaserProps = {
  text: string;
};

const BannerContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  padding-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and ${media.mobile} {
    // margin-top: 10px;
  }

  @media screen and ${media.tiny} {
    // padding-top: 32px;
    // padding-bottom: 32px;
  }
`;

const Spacer = styled.div`
  width: 800px;
  @media screen and ${media.mobile} {
    width: 40%;
  }
`;

const StyledText = styled.div`
  font-family: Brandon Text;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  font-size: 18px;
  font-weight: 390;

  color: #4c4c4c;

  @media screen and ${media.mobile} {
    padding: 10px;
  }
`;

export function BannerTeaserComponent(p: BannerTeaserProps) {
  return (
    <>
      <BannerContainer>
        <Spacer />
        <StyledText
          dangerouslySetInnerHTML={{
            __html: p.text
          }}
        />
        <Spacer />
      </BannerContainer>
    </>
  );
}
