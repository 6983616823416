import React from "react";
import { Category, ContentRef } from "../../../lib/CmsApi";
import ImageTextTeaserComponent from "../../common/ImageTextTeaserComponent";
import { Image } from "../../../lib/image";
import { AriaDate } from "../../../lib/formatters";

export interface Props {
  headerImage?: Image;
  slug: string;
  title: string;
  description: string;
  date: string;
  categories?: (ContentRef & Category)[];
  swap?: boolean;
  indent?: boolean;
  deeperHeading?: boolean;
  className?: string;
}

export default function ArticleTeaser(p: Props) {
  return (
    <ImageTextTeaserComponent
      className={p.className}
      headline={p.title}
      image={p.headerImage}
      text={p.description}
      deeperHeading={p.deeperHeading}
      bottomMargin
      swap={p.swap}
      indent={p.indent}
      overline={
        <>
          <AriaDate date={p.date} />
        </>
      }
      ctaRef={{ route: "newsArticle", params: { slug: p.slug } }}
      ctaLabel="Weiterlesen"
    />
  );
}

ArticleTeaser.getImages = ({ headerImage }: Props) => {
  return headerImage ? [headerImage] : [];
};
