import React from "react";
import Slider from "../Slider";
import CategoryClusterTile from "./CategoryClusterTile";
import { ApiResult, Segment } from "../../lib/CmsApi";
interface Props {
  categoryClusters: ApiResult<"loadCategoryCluster">[];
  segment: Segment;
}
const CategoryClusterSlider = ({ categoryClusters, segment }: Props) => {
  return (
    <>
      <Slider
        key={segment.slug}
        itemCountLarge={3}
        itemCountMedium={2}
        itemCountSmall={1}
        itemCountSuperTinyZoom={1}
        ariaLabel={`Themenbereiche aus ${segment.name}`}
        padded
      >
        {categoryClusters.map(cluster => (
          <CategoryClusterTile
            key={cluster.slug}
            overline="Themenbereich"
            headline={cluster.name}
            image={
              "header" in cluster && "image" in cluster.header
                ? cluster.header.image
                : undefined
            }
            link={{
              route: "categoryCluster",
              params: {
                segment: segment.slug,
                categoryCluster: cluster.slug
              }
            }}
          />
        ))}
      </Slider>
    </>
  );
};

CategoryClusterSlider.getImages = (props: Props) => {
  return (props.categoryClusters || [])
    .map(c => c.header && c.header.image)
    .filter(Boolean);
};

export default CategoryClusterSlider;
