import React from "react";
import FundingStory, {
  Props as FundingStoryProps
} from "../magazin/MagazineTeaser/FundingStory";
import Slider from "../Slider";
import { withSection } from "./Section";
import styled from "styled-components";
import Label from "../common/Label";
import { spacing } from "../theme";

interface Props {
  items: FundingStoryProps[];
  headlineLabel?: string;
}
const StyledLabel = styled(Label)`
  ${spacing("margin-bottom", 48)}
`;
function FundingStories(props: Props) {
  const { items, headlineLabel } = props;
  if (!items || !items.length) return null;
  if (items.length === 1) {
    return (
      <>
        {headlineLabel && (
          <StyledLabel big as="h2" aria-label={headlineLabel}>
            {headlineLabel}
          </StyledLabel>
        )}
        <div>
          <FundingStory {...items[0]} noBottomMargin small />
        </div>
      </>
    );
  }
  return (
    <>
      {headlineLabel ? (
        <StyledLabel big as="h2">
          {headlineLabel}
        </StyledLabel>
      ) : (
        <h2 aria-label={"Ausgewählte Förderportraits"}></h2>
      )}
      <Slider
        itemCountXLarge={1}
        itemCountLarge={1}
        itemCountMedium={1}
        itemCountSmall={1}
        ariaLabel={headlineLabel || "Ausgewählte Förderportraits"}
      >
        {items.map((i, idx) => (
          <FundingStory {...i} key={idx + i.title} noBottomMargin small />
        ))}
      </Slider>
    </>
  );
}

FundingStories.getImages = ({ items }: Props) => {
  let images = [];
  if (!items || !items.length) return images;
  items.forEach(el => (images = images.concat(FundingStory.getImages(el))));

  return images;
};
const FundingStoriesSection = withSection<Props>(FundingStories);

export default FundingStoriesSection;
