import React from "react";
import styled from "styled-components";
import Headline from "../common/Headline";
import Label from "../common/Label";
import Button from "../common/Button";
import { colors, font, media } from "../theme";
import Content from "../common/Content";
import { withSection } from "./Section";
import Paragraph from "../common/Paragraph";
import { FlexRow } from "../common/Flex";

const StyledNewsletter = styled.div`
  padding: calc(4px * 16) 0;
  background-color: ${colors.blue};
`;

const Nlheadline = styled(Headline)`
  color: white;
  padding: 0;
  font-weight: ${font.weightMedium};
  margin-bottom: calc(4px * 6);
`;

const NlLabel = styled(Label)`
  color: white;
  margin-bottom: calc(4px * 6);
`;
const NlParagraph = styled(Paragraph)`
  color: white;
  flex-shrink: 1;
  flex-grow: 1;
  width: 55%;
  margin: 0 calc(4px * 12) 0 0;
  @media screen and ${media.mobile} {
    margin: 0 0 calc(4px * 6) 0;
    width: 100%;
  }
`;
const NlButton = styled(Button)`
  flex-shrink: 0;
  flex-grow: 0;
  @media screen and ${media.tiny} {
    white-space: normal;
  }
  @media screen and ${media.superTiny} {
    white-space: normal;
    width: 100%;
  }
`;
const NlFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and ${media.mobile} {
    flex-direction: column;
  }
`;

type Props = {
  overline: string;
  headline: string;
  text: string;
  ctaLabel: string;
};

const NewsletterComponent = (props: Props) => (
  <StyledNewsletter>
    <Content padded>
      <NlLabel regular small>
        {props.overline}
      </NlLabel>
      <Nlheadline size={3} level={2}>
        {props.headline}
      </Nlheadline>
      <NlFlexRow>
        <NlParagraph small>{props.text}</NlParagraph>
        <NlButton route="newsletterSubscribe" whiteBorder>
          {props.ctaLabel}
        </NlButton>
      </NlFlexRow>
    </Content>
  </StyledNewsletter>
);

const NewsletterSection = withSection(NewsletterComponent, () => ({
  fullWidth: true,
  color: "white",
  noTopPadding: true,
  noBottomPadding: true
}));

export default NewsletterSection;
