import React from "react";
import styled, { css } from "styled-components";
import { Arrow, ArrowSm } from "../icons";
import { breakpoints, media } from "../../theme";
import useMediaQuery from "../../../lib/useMediaQuery";

type CrossTeaserArrowProps = {
  id?: string;
  title?: string;
};

const CrossTeaserArrow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 52px 0px;
  gap: 10px;

  width: 100%;
  height: 306px;

  background: #004669;

  @media screen and ${media.tablet} {
    height: 220px;
    width: 100%;
  }

  @media screen and ${media.tiny} {
    height: 202px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media screen and ${media.mobile} {
    width: 100%;
  }
`;

const CTContent = styled.p`
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 24px;
  line-height: 30px;

  color: #ffffff;

  margin-bottom: 23px;

  @media screen and ${media.tablet} {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media screen and ${media.mobile} {
    font-size: 16px;
  }
`;

const Contents = styled.div`
  padding: 24px;
`;

function CrossteaserArrow({ id, title }: CrossTeaserArrowProps) {
  const isTablet = useMediaQuery(breakpoints.tablet);
  return (
    <CrossTeaserArrow>
      <Contents>
        <CTContent>{title}</CTContent>
        {isTablet ? <ArrowSm /> : <Arrow />}
      </Contents>
    </CrossTeaserArrow>
  );
}

export default CrossteaserArrow;
