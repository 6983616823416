import React from "react";
import styled from "styled-components";
import { font, media, fontSizing, colors } from "../theme";
import { RouteName, RouteParams } from "../../lib/routes";
import Label from "../common/Label";
import { SliderCard } from "../common/Card";
import Headline from "../common/Headline";
import Link from "../utils/Link";
import { Image as ImageType } from "../../lib/image";
import { responsiveBackgroundImage } from "../../lib/responsiveImage";
import getImageFocus from "../../lib/getImageFocus";

const StyledItem = styled(SliderCard)`
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

const ItemHeadline = styled(Headline)`
  font-weight: ${font.weightMedium};
  ${fontSizing("label", 24)};
`;

const Image = styled.div<{ image: ImageType }>`
  width: 100%;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
  background-color: ${colors.anotherblue};
  ${({ image }) =>
    image
      ? responsiveBackgroundImage({
          image,
          sizes: {
            0: 440
          }
        })
      : undefined}
    background-position: ${({ image }) => getImageFocus(image)}
`;

const Content = styled.div`
  padding: calc(4px * 12);
  @media screen and ${media.tablet} {
    padding: calc(4px * 6);
  }
  @media screen and ${media.tiny} {
    padding: calc(4px * 4);
  }
`;
interface Props {
  image?: ImageType;
  overline: string;
  headline: string;
  link: { route: RouteName; params?: RouteParams };
}

export const CategoryClusterTile: React.FC<Props> = (p: Props) => (
  <Link {...p.link}>
    <StyledItem white as={"a"} tabIndex={0}>
      <Image
        image={p.image}
        role="img"
        aria-label={p.image && p.image.alt ? p.image.alt : p.headline}
      />
      <Content>
        <Label blue tiny>
          {p.overline}
        </Label>
        <ItemHeadline size={5} level={3}>
          {p.headline}
        </ItemHeadline>
      </Content>
    </StyledItem>
  </Link>
);

export default CategoryClusterTile;
