import EssentialTeaserComponent, {
  EssentialTeaserProps
} from "../common/EssentialTeaserComponent";
import { withSection } from "./Section";

const EssentialTeaserSection = withSection<EssentialTeaserProps>(
  EssentialTeaserComponent,
  null
);
export default EssentialTeaserSection;
