import React from "react";
import ContactsSlider, { ContactsSliderProps } from "../common/ContactSlider";
import { SectionProps, withSection } from "./Section";
import { colors } from "../theme";

type Props = ContactsSliderProps;
export function Contacts({
  sectionProps,
  ...sliderProps
}: Props & { sectionProps: SectionProps }) {
  return <ContactsSlider {...sliderProps} small={sectionProps.leftIndented} noBackground={sectionProps.noBackground} />;
}

Contacts.getImages = (props: ContactsSliderProps) => {
  return ContactsSlider.getImages(props);
};
const ContactsSection = withSection<ContactsSliderProps>(
  Contacts,
  () => ({
    color: colors.anotherblue
  }),
  props => !props.contacts || !props.contacts.length
);

export default ContactsSection;
