import React from "react";
import { colors } from "../../theme";
import ShowCaseItem, { Props as ShowCaseItemProps } from "./ShowCaseItem";
import HeaderWithIntro from "../../common/HeaderWithIntro";
import Grid from "../../common/Grid";
import { withSection } from "../Section";

interface Props {
  cases: ShowCaseItemProps[];
  headline: string;
  intro?: string;
  inline?: boolean;
  noBackground?: boolean;
  className?: string;
}
const ShowCaseComponent = (p: Props) => (
  <>
    <HeaderWithIntro
      headline={p.headline}
      intro={p.intro}
      size={1}
      level={2}
      small
      blue
    />
    <Grid
      columns={3}
      tabletColumns={2}
      tinyColumns={1}
      columnGap={"40px"}
      rowGap={"40px"}
      role="list"
    >
      {p.cases.map((c, idx) => (
        <ShowCaseItem key={idx} {...c} />
      ))}
    </Grid>
  </>
);
const ShowCaseSection = withSection<Props>(
  ShowCaseComponent,
  () => ({
    color: colors.pigeonSuperLight
  }),
  p => !p.cases || !p.cases.length
);
export default ShowCaseSection;
