import React from "react";
import { colors, fontSizing, spacing } from "../theme";
import {
  Calendar as CalendarIcon,
  Location as LocationIcon,
  Organizer as OrganizerIcon,
  PartTimeClock as TimeIcon
} from "../common/icons";
import styled, { css } from "styled-components";
import { AriaDate } from "../../lib/formatters";
const Facts = styled.div`
  ${fontSizing("text", 16)}
  ${spacing("margin-bottom", 24)}
`;

const Fact = styled.div`
  color: ${colors.blue};
  &:not(:last-child) {
    ${spacing("margin-bottom", 16)}
  }
  display: flex;
  flex-direction: row;
  > span {
    display: inline-block;
  }
`;

const StyledIcon = css`
  margin-right: calc(4px * 3);
  width: 1em;
  height: 1em;
  color: ${colors.pigeonLight};
  flex-shrink: 0;
  margin-top: 4px;
`;

const Calendar = styled(CalendarIcon)`
  ${StyledIcon}
`;

const Location = styled(LocationIcon)`
  ${StyledIcon}
`;
const Organizer = styled(OrganizerIcon)`
  ${StyledIcon}
`;

const Time = styled(TimeIcon)`
  ${StyledIcon}
`;

interface Props {
  dates: { date: string; time: string }[];
  organizer?: string;
  location?: string;
}
const EventFacts = (p: Props) => {
  if (!p.dates) return null;

  const startDate = p.dates[0];
  const endDate = p.dates[1];
  const startTime = p.dates[0];
  const endTime = p.dates[1];
  return (
    <Facts role="list">
      <Fact role="listitem">
        <Calendar aria-hidden={"true"} />
        <span>
          {" "}
          <AriaDate date={startDate.date} />
          {endDate && endDate.date !== startDate.date && (
            <>
              {" bis "}
              <AriaDate date={endDate.date} />
            </>
          )}
        </span>
      </Fact>
      <Fact role="listitem">
        <Time aria-hidden={"true"} />
        <span>
          {startTime.time}
          {endTime ? ` bis ${endTime.time} Uhr` : " Uhr"}
        </span>
      </Fact>
      {p.organizer && (
        <Fact role="listitem">
          <Organizer aria-hidden={"true"} />
          <span> {p.organizer}</span>
        </Fact>
      )}
      {p.location && (
        <Fact role="listitem">
          <Location aria-hidden={"true"} />
          <span>{p.location}</span>
        </Fact>
      )}
    </Facts>
  );
};
export default EventFacts;
