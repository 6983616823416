import React from "react";
import Headline from "../common/Headline";
import { withSection } from "./Section";
import { ImageTextTeaserCheckerboard } from "../common/CheckerboardComponent";
import { ImageTextTeaserProps } from "../common/ImageTextTeaserComponent";

interface Props {
  headline?: string;
  entries: ImageTextTeaserProps[];
}

const Checkerboard = (p: Props) => {
  return (
    <>
      {p.headline && (
        <Headline size={3} level={2}>
          {p.headline}
        </Headline>
      )}
      <ImageTextTeaserCheckerboard entries={p.entries} blue deeperHeading />
    </>
  );
};

Checkerboard.getImages = ({ entries }: Props) => {
  return ImageTextTeaserCheckerboard.getImages({ entries });
};
const CheckerboardSection = withSection<Props>(
  Checkerboard,
  null,
  p => !p.entries || p.entries.length === 0
);
export default CheckerboardSection;
