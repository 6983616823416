import React from "react";
import styled from "styled-components";
import EventTile, { Props as EventTileProps } from "./EventTile";
import Slider from "../Slider/index";
import Button from "../common/Button";
import HeaderWithIntro from "../common/HeaderWithIntro";
import { RouteParams } from "../../lib/routes";
import { spacing } from "../theme";
import { HeadingLevel } from "../common/Headline";

const StyledSlider = styled(Slider)``;
const StyledButton = styled(Button)`
  ${spacing("margin-top", 80)}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export interface EventSliderProps {
  headline?: string;
  copy?: string;
  events: EventTileProps[];
  params?: RouteParams;
  className?: string;
  headlineSize?: HeadingLevel;
  maxItems?: number;
}
export const filteredAndSortedEvents = (events: EventSliderProps["events"]) => {
  let result = events.filter(Boolean);
  if (!result) return [];

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  result.sort((d1, d2) => {
    if (d1.dates[0].date > d2.dates[0].date) return 1;
    if (d1.dates[0].date < d2.dates[0].date) return -1;
    return 0;
  });

  console.log("SORTED EVENTS", result.length);

  result = result.filter(({ dates }) => {
    const d1 = new Date(dates[0].date);
    const d2 = dates[1] ? new Date(dates[1].date) : undefined;

    return !(d2 && d2 < now) && !(!d2 && d1 && d1 < now);
  });

  return result;
};

export default function EventSlider(props: EventSliderProps) {
  const {
    headline = "Veranstaltungen",
    copy,
    params,
    className,
    headlineSize = 1,
    maxItems
  } = props;

  if (!props.events) return null;

  let events = filteredAndSortedEvents(props.events).filter(Boolean);

  if (maxItems) {
    events = events.slice(0, maxItems);
  }
  if (events.length === 0) return null;

  return (
    <Container className={className}>
      <HeaderWithIntro
        headline={headline}
        intro={copy}
        size={headlineSize}
        blue
        level={2}
      />
      <StyledSlider
        itemCountXLarge={3}
        itemCountLarge={3}
        itemCountSmall={1}
        ariaLabel={headline}
        padded
      >
        {events.map((event, index) => {
          return <EventTile {...event} key={index} />;
        })}
      </StyledSlider>
      <StyledButton alignCenter route="events" params={params}>
        Mehr Veranstaltungen ansehen
      </StyledButton>
    </Container>
  );
}
