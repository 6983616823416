import TextTeaser from "./TextTeaser";
import LinkTextTeaser from "./LinkTextTeaser";
import ImageTextTeaser from "./ImageTextTeaser";
import ShowCase from "./ShowCase";
import Text from "./RichText";
import Accordion from "./Accordion";
import Contacts from "./Contacts";
import Downloads from "./Downloads";
import FundingStories from "./FundingStories";
import Image from "./Image";
import ProgramTeaser from "./ProgramTeaser";
import DataPrivacy from "./DataPrivacy";
import Table from "./Table";
import Checkerboard from "./Checkerboard";
import Finder from "./Finder";
import DiaShow from "./DiaShow";
import CustomerVoices from "./CustomerVoices";
import HeroSlider from "./HeroSlider";
import Magazin from "./Magazin";
import Newsletter from "./Newsletter";
import Jobs from "./Jobs";
import Custom from "./Custom";
import CollapsibleText from "./CollapsibleText";
import BannerTeaser from "./BannerTeaser";
import EssentialTeaser from "./EssentialTeaser";
import ReportsSection from "./Reports";
import DevelopmentGoals from "./DevelopmentGoals";
import NachhaltigkeitSlider from "./NachhaltigkeitSlider";

export default {
  TextTeaser,
  LinkTextTeaser,
  ImageTextTeaser,
  CollapsibleText,
  ShowCase,
  Text,
  Accordion,
  Contacts,
  Downloads,
  Image,
  FundingStories,
  ProgramTeaser,
  DataPrivacy,
  Table,
  Checkerboard,
  DiaShow,
  CustomerVoices,
  HeroSlider,
  Newsletter,
  Jobs,
  Finder,
  Magazin,
  BannerTeaser,
  Custom,
  EssentialTeaser,
  ReportsSection,
  DevelopmentGoals,
  NachhaltigkeitSlider
};
