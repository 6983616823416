import TextTeaserComponent, {
  TextTeaserProps
} from "../common/TextTeaserComponent";
import { withSection } from "./Section";

const TextTeaserSection = withSection<TextTeaserProps>(
  TextTeaserComponent,
  null
);
export default TextTeaserSection;
