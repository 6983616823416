import React from "react";
import Label from "../common/Label";
import Button from "../common/Button";
import Paragraph from "../common/Paragraph";
import SimpleInformationCard from "../common/SimpleInformationCard";
import Grid, { generateAutoPlacementForIE } from "../common/Grid";
import { FlexColumn } from "../common/Flex";
import Headline from "../common/Headline";
import styled from "styled-components";
import { media, THEME_SCALE } from "../theme";
import { withSection } from "./Section";

const StyledGrid = styled(Grid)`
  grid-template-columns: 3fr 4fr;
  ${p =>
    generateAutoPlacementForIE(p.children, "3fr 4fr", {
      row: 4 * 12,
      column: 4 * 5
    })}
  @media screen and ${media.mobile} {
  ${p =>
    generateAutoPlacementForIE(p.children, "1fr", {
      row: 4 * 12,
      column: 4 * 5
    })}
  }
`;
const StyledButton = styled(Button)`
  margin-top: auto;
`;
const StyledCard = styled(SimpleInformationCard)`
  max-width: ${350 * THEME_SCALE}px;
`;
interface Props {
  program: {
    name: string;
    abstract: string;
    slug: string;
    type: {
      name: string;
    };
  };
  inline?: boolean;
}
const ProgramTeaserComponent = ({ program: p }: Props) => (
  <>
    <Label>Förderangebot</Label>
    <StyledGrid columns={2} mobileColumns={1}>
      <FlexColumn>
        <Headline size={3} level={2}>
          {p.name}
        </Headline>
        <StyledCard
          key={p.slug}
          route="shortProgram"
          params={{
            slug: p.slug
          }}
          label={p.type.name}
          text={p.name}
        />
      </FlexColumn>
      <FlexColumn alignItems="flex-start">
        <Paragraph serif>{p.abstract}</Paragraph>
        <StyledButton
          route="shortProgram"
          params={{
            slug: p.slug
          }}
        >
          Förderangebot ansehen
        </StyledButton>
      </FlexColumn>
    </StyledGrid>
  </>
);
const ProgramTeaserSection = withSection<Props>(
  ProgramTeaserComponent,
  null,
  p => !p.program
);
export default ProgramTeaserSection;
