import React from "react";
import styled, { css } from "styled-components";
import { colors, font, fontSizing } from "../theme";
import { withSection } from "./Section";

export const TH = styled.th<{
  columnWidth?: number;
  striped?: boolean;
  colspan?: number;
}>`
  ${fontSizing("label", 28)}
  margin:0;
  padding: 1em 0.5em;
  text-align: center;
  font-weight: ${font.weightMedium};
  ${p =>
    p.columnWidth &&
    css`
      width: ${p.columnWidth}%;
    `}
  ${p =>
    p.striped &&
    css`
      &:nth-child(2n) {
        background-color: ${colors.lightGrey};
      }
    `}
`;

export const TD = styled.td<{ striped?: boolean; bold?: boolean }>`
  ${fontSizing("label", 24)}
  margin:0;
  padding: 1em 0.5em;
  text-align: center;
  font-weight: ${p => (p.bold ? font.weightBold : font.weightRegular)};
  font-family: ${font.main};
  ${p =>
    p.striped &&
    css`
      &:nth-child(2n) {
        background-color: ${colors.lightGrey};
      }
    `}
`;
export const TR = styled.tr<{ striped?: boolean }>`
  ${p =>
    p.striped &&
    css`
      &:nth-child(2n-1) {
        background-color: ${colors.lightGrey};
      }
    `}
`;

export const TableBox = styled.table`
  border-style: solid;
  border-width: 2px 0 2px 0;
  border-color: ${colors.pigeonSuperLight};
  border-spacing: 0;
  > tbody,
  > thead {
    width: 100%;
  }
  width: 100%;
`;

const TableCaption = styled.caption`
  text-align: left;
  padding-bottom: 12px;
`;

export interface Props {
  table: string;
  seperator: "," | ";";
  striped: "-" | "vertikal" | "horizontal";
  inline?: boolean;
  caption?: string;
}
function Table(props: Props) {
  if (!props.table) {
    return null;
  }
  let rows = props.table.split("\n");
  let thead;
  // get the number of cols of the longest row to style the columnWidth of TH correect
  let colsOflongestRow = 0;
  rows.forEach(row => {
    const count =
      (row.match(new RegExp(props.seperator, "g")) || []).length + 1;
    if (count > colsOflongestRow) {
      colsOflongestRow = count;
    }
  });
  let headRow;
  [headRow, ...rows] = rows;

  thead = (
    <thead>
      <tr>
        {headRow.split(props.seperator).map((col, j) => (
          <TH
            key={"row" + j}
            columnWidth={100 / colsOflongestRow}
            striped={props.striped === "vertikal"}
          >
            {col}
          </TH>
        ))}
      </tr>
    </thead>
  );
  function rowData(row: string, i: number) {
    let data = row.split(props.seperator);
    data = data.concat(
      Array.from({ length: colsOflongestRow - data.length }).map(() => "")
    );
    return data.map((col, j) =>
      j === 0 ? (
        <TH key={i + "" + j} striped={props.striped === "vertikal"} scope="row">
          {col}
        </TH>
      ) : (
        <TD key={i + "" + j} striped={props.striped === "vertikal"}>
          {col}
        </TD>
      )
    );
  }
  return (
    <TableBox>
      {props.caption && <TableCaption>{props.caption}</TableCaption>}
      {thead}
      <tbody>
        {rows.map((row, i) => {
          return (
            <TR key={i} striped={props.striped === "horizontal"}>
              {rowData(row, i)}
            </TR>
          );
        })}
      </tbody>
    </TableBox>
  );
}
const TableSection = withSection(Table, () => ({
  noTopPadding: true,
  noBottomPadding: true,
  contentBlock: true
}));
export default TableSection;
