import React from "react";
import styled from "styled-components";
import Content from "../common/Content";
import Slider from "../Slider";
import FundingStory from "../magazin/MagazineTeaser/FundingStory";
import ArticleTeaser from "../magazin/MagazineTeaser/ArticleTeaser";

import Button from "../common/Button";
import { spacing } from "../theme";
import HeaderWithIntro from "../common/HeaderWithIntro";
import AlignRight from "../common/AlignRight";
import { withSection } from "./Section";
import { Props as EventProps } from "../EventSlider/EventTile";
import EventSlider from "../EventSlider";
import { Image } from "../../lib/image";
import Headline from "../common/Headline";

interface Props {
  news?: {
    headerImage?: Image;
    slug: string;
    title: string;
    description: string;
    date: string;
  }[];
  events?: {
    headline: string;
    intro?: string;
    items?: EventProps[];
  };
  stories?: {
    headerImage?: Image;
    slug: string;
    title: string;
    programs: {
      slug: string;
      type: {
        name: string;
      };
      name: string;
    }[];
  }[];
}

const StyledButton = styled(Button)`
  ${spacing("margin-top", 64)}
`;
const StyledEventSlider = styled(EventSlider)`
  ${spacing("margin-top", 80)}
  ${spacing("margin-bottom", 160)}
`;
const StyledHeadline = styled(Headline)`
  ${spacing("margin-bottom", 64)}
`;
const Magazin = (p: Props) => {
  const hasEvents = p.events && p.events.items && !!p.events.items.length;
  return (
    <>
      <HeaderWithIntro headline="Aktuelles" size={1} overline="Magazin">
        <AlignRight>
          <Button route={"magazin"}>Zum Magazin</Button>
        </AlignRight>
      </HeaderWithIntro>

      {p.news
        .filter(Boolean)
        .map(
          (item, i) =>
            item.title && (
              <ArticleTeaser
                key={item.slug + i}
                {...item}
                swap={!!(i % 2)}
                indent
                deeperHeading
              />
            )
        )}
      {hasEvents && p.events.headline && (
        <StyledEventSlider
          headlineSize={1}
          headline={p.events.headline}
          events={p.events.items}
          copy={p.events.intro}
        />
      )}
      <Content smallBottomMargin>
        <StyledHeadline size={1} level={2}>
          Förderportraits
        </StyledHeadline>

        <Slider
          itemCountLarge={1}
          itemCountXLarge={1}
          itemCountMedium={1}
          itemCountSmall={1}
          ariaLabel="Förderportraits"
        >
          {p.stories.map(
            (item, i) =>
              item &&
              item.title && (
                <FundingStory key={item.slug + i} {...item} noBottomMargin />
              )
          )}
        </Slider>
      </Content>
      <StyledButton route="magazin" alignCenter>
        Alle Magazineinträge ansehen
      </StyledButton>
    </>
  );
};
Magazin.getImages = (props: Props) => {
  let images = [];

  props.news.filter(Boolean).forEach(el => {
    images = images.concat(ArticleTeaser.getImages(el));
  });
  props.stories.filter(Boolean).forEach(el => {
    images = images.concat(FundingStory.getImages(el));
  });

  return images;
};

const MagazinSection = withSection<Props>(
  Magazin,
  null,
  p => (!p.news && !p.stories) || (!p.stories.length || !p.news.length)
);

export default MagazinSection;
