import React from "react";
import HeaderWithIntro from "../common/HeaderWithIntro";
import Button from "../common/Button";
import Slider from "../Slider";
import JobOfferTile from "../common/JobOfferTile";
import styled from "styled-components";
import { AlignRightOnTablet } from "../common/AlignRight";
import { withSection } from "./Section";

interface Props {
  title: string;
  jobs?: {
    slug: string;
    name: string;
    intro: string;
    employmentType?: string;
    employmentSchema?: string;
    category: {
      slug: string;
      name: string;
    };
  }[];
  small?: boolean;
  inline?: boolean;
}
const StyledButton = styled(Button)`
  margin: calc(4px * 2);
`;
const JobsComponent = (props: Props) => (
  <>
    <HeaderWithIntro headline={props.title}>
      <AlignRightOnTablet>
        <StyledButton route="career" small>
          Zur Karriereseite
        </StyledButton>
      </AlignRightOnTablet>
    </HeaderWithIntro>
    <Slider
      itemCountXLarge={3}
      itemCountLarge={3}
      ariaLabel={props.title}
      padded
    >
      {props.jobs.map((job, i) => {
        return (
          job.slug && (
            <JobOfferTile key={job.slug + i} {...job} small={!!props.small} />
          )
        );
      })}
    </Slider>
  </>
);
const JobsSection = withSection<Props>(
  JobsComponent,
  null,
  p => !p.jobs || !p.jobs.length
);
export default JobsSection;
