import React from "react";
import DefaultDownloadsList, { Download } from "../common/Downloads";
import { colors } from "../theme";
import { withSection } from "./Section";
import { ContentRef } from "../../lib/CmsApi";
interface Props {
  headline?: string;
  documents: (ContentRef & Download)[];
}
const Downloads = (props: Props) => (
  <DefaultDownloadsList downloads={props.documents} headline={props.headline} />
);
const DownloadSection = withSection<Props>(
  Downloads,
  () => ({
    color: colors.pigeonSuperLight
  }),
  props => !props.documents || !props.documents.length
);
export default DownloadSection;
