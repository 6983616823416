import * as React from "react";
import Label from "../common/Label";
import Headline from "../common/Headline";
import Paragraph from "../common/Paragraph";
import Button from "../common/Button";
import styled from "styled-components";
import { ContentRef } from "../../lib/CmsApi";
import Slider from "../Slider";
import Content from "../common/Content";
import { withSection } from "./Section";
import { breakpoints, colors, fontSizing, media, spacing } from "../theme";
import { Image } from "../../lib/image";
import staticMedia from "../../lib/static";

const StyledSlideShowItem = styled.div`
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
  background-color: ${colors.blue};
`;

const SlideshowContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 5%;
  padding-left: 10%;
  ${spacing("padding-top", 40)}

  ${spacing("padding-right", 40)}
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 40px;
  @media screen and ${media.tablet} {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media screen and ${media.desktop} {
    padding-bottom: 44px;
  }
`;
const StyledHeadline = styled(Headline)`
  color: #fff;
  font-weight: 500;
  ${fontSizing("diashow", 48)}
  margin: 0;
  padding-top: 22px;
  @media screen and ${media.desktop} {
    padding-top: 16px;
  }
`;
const StyledParagraph = styled(Paragraph)`
  color: #fff;
  font-weight: bold;
  margin: 0;
  display: inline-block;

  font-size: 16px;
  @media screen and ${media.tablet} {
    font-size: 12px;
    padding: 16px 0;
  }
  @media screen and ${media.mobile} {
    font-size: 24px;
  }
  @media screen and ${media.tiny} {
    font-size: 16px;
  }
`;
const Box = styled.div`
  height: auto;
  width: 100%;
  background-color: ${colors.blue};
`;
const IconBox = styled.div`
  width: 100%;
  text-align: right;
  padding-bottom: calc(4px * 3);
  height: 25%;
  img {
    height: 100%;
  }
`;

const SlideshowItem = ({
  icon,
  headline,
  text,
  ariaHidden
}: {
  icon?: Image;
  headline: string;
  text?: string;
  ariaHidden?: boolean;
}) => (
  <StyledSlideShowItem aria-hidden={ariaHidden}>
    <SlideshowContent>
      {icon && (
        <IconBox>
          <img src={staticMedia(icon)} alt="" />
        </IconBox>
      )}
      <StyledHeadline size={1} level={3}>
        {headline}
      </StyledHeadline>
      {text && <StyledParagraph>{text}</StyledParagraph>}
    </SlideshowContent>
  </StyledSlideShowItem>
);

interface Props {
  overline: string;
  headline: string;
  intro: string;
  ctaRef: ContentRef;
  ctaLabel: string;
  dias: {
    headline: string;
    copy?: string;
    icon?: Image;
    ariaHidden?: boolean;
  }[];
}

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:first-child {
    padding-bottom: 24px;
  }
  @media screen and (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    align-items: flex-start;
    > *:first-child {
      width: 70%;
      padding-bottom: 0;
      padding-right: 24px;
    }
    > *:last-child {
      width: 30%;
    }
  }
  @media screen and (min-width: ${breakpoints.wide}px) {
    flex-direction: row;
    > *:first-child {
      width: 75%;
      padding-right: 32px;
    }
    > *:last-child {
      width: 25%;
    }
  }
`;

const DiaShowComponent = (p: Props) => (
  <Grid>
    <Content>
      <Label big>{p.overline}</Label>
      <Headline size={1} level={2}>
        {p.headline}
      </Headline>
      <Paragraph serif>{p.intro}</Paragraph>
      <Button
        href={p.ctaRef._url}
        alignLeft
        aria-label={p.ctaLabel + ", " + p.headline}
      >
        {p.ctaLabel}
      </Button>
    </Content>
    <Box>
      <Slider
        showArrowControls={false}
        itemCountXLarge={1}
        itemCountLarge={1}
        itemCountMedium={1}
        itemCountSmall={1}
        innerButtons
        autoHeight
        ariaLabel={p.headline}
        ariaHidden={p.dias.length > 1 ? false : p.dias[0].ariaHidden} // in case there is only one slide that should be aria hidden, hide whole component
      >
        {p.dias.map((dia, i) => {
          return (
            <SlideshowItem
              key={i}
              headline={dia.headline}
              text={dia.copy}
              icon={dia.icon}
              ariaHidden={dia.ariaHidden}
            />
          );
        })}
      </Slider>
    </Box>
  </Grid>
);

DiaShowComponent.getImages = (props: Props) => {
  return props.dias.map(d => d.icon);
};

const DiaShowSection = withSection<Props>(
  DiaShowComponent,
  () => ({ fullWidth: false }),
  p => !p.dias || !p.dias.length
);
export default DiaShowSection;
