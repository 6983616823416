import React from "react";
import styled from "styled-components";
import { Media, MediaRef } from "../../lib/CmsApi";
import { withSection } from "./Section";
import { responsiveImageSrcset } from "../../lib/responsiveImage";

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface Props {
  image: Media & MediaRef;
}
function ImageComponent({ image }: Props) {
  if (!image || !image._src) return null;
  return <Image {...responsiveImageSrcset({ image })} alt={image.alt || ""} />;
}

ImageComponent.getImages = ({ image }: Props) => {
  return image ? [image] : [];
};
const ImageSection = withSection<Props>(
  ImageComponent,
  () => ({ color: "white", noTopPadding: true, noBottomPadding: true }),
  props => !props.image || !props.image._src
);

export default ImageSection;
