import React from "react";
import styled, { css } from "styled-components";
import { font, border, media, colors, THEME_SCALE } from "../../theme";
import { Cross } from "../icons";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and ${media.tablet} {
    font-size: ${font.sizeXXS};
    flex-direction: column;
  }
  @media screen and ${media.mobile} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media screen and ${media.superTinyZoom125} {
    overflow: hidden;
    word-break: break-word;
    hyphens: auto;
  }
`;

export const FilterLabel = styled.div`
  border-radius: ${border.radius} 0 0 ${border.radius};
  padding: calc(4px * 6 * ${THEME_SCALE}) calc(4px * 8 * ${THEME_SCALE});
  background: ${colors.pigeonSuperLight};
  color: ${colors.blueDark};
  align-items: center;
  display: inline-flex;
  white-space: nowrap;

  @media screen and ${media.tablet} {
    padding: calc(4px * 3) calc(4px * 6);
    width: 100%;
    text-align: center;
    display: inline;
  }

  @media screen and ${media.mobile} {
    // margin-bottom: calc(4px * 6);
    border-radius: 2px;
    width: 100%;
    text-align: center;
    display: inline;
    font-weight: 450;
  }
`;

export const FilterButton = styled.button<{
  isActive?: boolean;
  notActive?: boolean;
}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: calc(4px * 6 * ${THEME_SCALE}) calc(4px * 8 * ${THEME_SCALE});
  background: white;
  font-size: inherit;
  border: none;
  margin: 0;
  border-right: 2px solid ${colors.pigeonSuperLight};
  color: ${colors.blueDark};
  background-color: ${colors.lightBlue};
  transition: background-color 120ms ease-out;
  font-family: inherit;
  cursor: pointer;
  /* outline: none; */
  &:last-child {
    border-right: none;
    border-radius: 0 ${border.radius} ${border.radius} 0;
  }
  @media screen and ${media.tablet} {
    padding: calc(4px * 3) calc(4px * 6);
    width: 100%;
    text-align: center;
    display: inline;
    margin-bottom: 2px;
    border-right: 3.54px solid ${colors.pigeonSuperLight};
    border-left: 3.54px solid ${colors.pigeonSuperLight};

    &:last-child {
      border-right: 3.54px solid ${colors.pigeonSuperLight};
      border-radius: 0 ${border.radius} ${border.radius} 0;
      border-bottom: 3.54px solid ${colors.pigeonSuperLight};
    }
  }

  @media screen and ${media.mobile} {
    margin-bottom: 2px;
    border-right: none;
    border-radius: 2px;
    &:last-of-type {
      margin-bottom: 0;
    }
    width: 100%;
    text-align: center;
    display: inline;
    border-right: 3.54px solid ${colors.pigeonSuperLight};
    border-left: 3.54px solid ${colors.pigeonSuperLight};

    &:last-child {
      border-right: 3.54px solid ${colors.pigeonSuperLight};
      border-radius: 0 ${border.radius} ${border.radius} 0;
      border-bottom: 3.54px solid ${colors.pigeonSuperLight};
    }
  }

  ${p =>
    p.isActive &&
    css`
      background-color: ${colors.calypso};
      color: ${colors.white};
    `}
  ${p =>
    p.notActive &&
    css`
      @media screen and ${media.mobile} {
        display: none;
      }
    `}
`;
const StyledCross = styled(Cross)<{ hidden?: boolean }>`
  margin-left: 1ch;
  height: 1em;
  cursor: pointer;
  display: none;
  ${p =>
    !p.hidden &&
    css`
      @media screen and ${media.mobile} {
        display: block;
      }
    `}
`;

export interface FilterOption {
  label: string;
  id: string;
}
interface Props {
  label?: string;
  options: FilterOption[];
  selected?: string;
  ariaButtonPrefix?: string;
  onSelect: (selectedOption?: FilterOption, e?: React.MouseEvent) => void;
  onClearFilter: (e?: MouseEvent) => void;
}

const Filter: React.FC<Props> = ({
  label,
  options,
  onSelect,
  selected,
  onClearFilter,
  ariaButtonPrefix
}) => {
  return (
    <Wrapper>
      {label && <FilterLabel>{label}</FilterLabel>}
      {options.map(option => {
        return (
          <FilterButton
            key={option.id}
            onClick={e => onSelect(option, e)}
            isActive={selected === option.id}
            // notActive={selected && selected !== option.id}
          >
            {option.label}
          </FilterButton>
        );
      })}
    </Wrapper>
  );
};
export default Filter;
