import React from "react";
import { ContentRef, Media, MediaRef } from "../../lib/CmsApi";
import Label from "../common/Label";
import Content from "../common/Content";
import Slider from "../Slider";
import styled from "styled-components";
import { media, colors } from "../theme";
import { withSection } from "./Section";
import Button from "../common/Button";

interface ItemProps {
  title: string;
  description: string;
  callToActionLink?: ContentRef;
  image: MediaRef & Media;
  disable?: boolean;
}
const SlideContainer = styled(Content as any)<{ backgroundImage?: boolean }>`
  align-items: flex-start;
  @media screen and ${media.mobile} {
    flex-direction: column;
  }
  height: 500px;
  background: url(${p => p.backgroundImage}) no-repeat center center;
  background-size: cover;
  @media screen and ${media.mobile} {
    justify-content: flex-end;
    padding-bottom: 40px;
    height: 100%;
    min-height: 400px;
  }
`;
const DescBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  width: 568px;
  max-height: 368px;

  background: #ffffff;
  margin-left: calc(40% - 568px / 2);
  align-self: center;
  @media screen and ${media.mobile} {
    width: 90%;
    margin-left: unset;
    padding: 20px;
    max-height: 100%;
  }

  @media screen and ${media.superTinyZoom125} {
    padding: 10px;
  }
`;
const DescBoxHeading = styled.h1`
  width: 488px;
  font-weight: 390;
  font-size: 48px;
  line-height: 64px;
  color: ${colors.blue};
  margin-top: 0;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and ${media.mobile} {
    width: 100%;
    font-size: 28px;
    line-height: 36px;
    -webkit-line-clamp: initial;
    word-break: break-word;
    hyphens: auto;
  }
`;
const DescBoxSubHeading = styled(Label)<{ noButton: boolean }>`
  color: ${colors.blue};
  font-size: 24px;
  line-height: 36px;
  margin-bottom: ${p => (p.noButton ? "0px" : "24px")};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and ${media.mobile} {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;

const NachhaltigkeitSliderItem = (p: ItemProps) => {
  return (
    <SlideContainer row backgroundImage={p.image._src}>
      <DescBoxContainer>
        <DescBoxHeading> {p.title}</DescBoxHeading>
        <DescBoxSubHeading noButton={!p.callToActionLink ? true : false}>
          {p.description}
        </DescBoxSubHeading>
        {p.callToActionLink ? (
          <Button small={!!media.mobile} href={p.callToActionLink._url}>
            Mehr erfahren
          </Button>
        ) : (
          ""
        )}
      </DescBoxContainer>
    </SlideContainer>
  );
};

interface NachhaltigkeitSliderProps {
  sliderItems: ItemProps[];
}
const NachhaltigkeitSlider = (p: NachhaltigkeitSliderProps) => (
  <Slider
    itemCountXLarge={1}
    itemCountLarge={1}
    itemCountMedium={1}
    itemCountSmall={1}
    ariaLabel={" "} // await for text from UX team
    showArrowControls
    borderedOverlayButtons
    innerButtons
    alwaysMobileControls={false}
    hideArrowsOnMobile={true}
    heroSlider={true}
  >
    {p.sliderItems.map((sliderItem, i) => {
      return (
        <NachhaltigkeitSliderItem
          key={i}
          title={sliderItem.title}
          {...sliderItem}
        />
      );
    })}
  </Slider>
);

const NachhaltigkeitSlidersSection = withSection(NachhaltigkeitSlider, () => ({
  fullWidth: true,
  color: "white",
  noTopPadding: true,
  noBottomPadding: true
}));
export default NachhaltigkeitSlidersSection;
