import styled from "styled-components";
import { media } from "../theme";
import ImageTextTeaserComponent, {
  ImageTextTeaserProps
} from "./ImageTextTeaserComponent";
const CheckerboardComponent = styled.div`
  article:not(.swapped) {
    padding-left: calc(1 / 12 * 100%);
    @media screen and ${media.tablet} {
      padding-left: 0;
    }
  }
  article.swapped {
    padding-right: calc(1 / 12 * 100%);
    @media screen and ${media.tablet} {
      padding-right: 0;
    }
  }
  article.no-image {
    padding-right: calc(2 / 12 * 100%);
    padding-left: calc(2 / 12 * 100%);
    @media screen and ${media.tablet} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
export default CheckerboardComponent;
export interface Props {
  entries: ImageTextTeaserProps[];
  deeperHeading?: boolean;
  blue?: boolean;
}
export const ImageTextTeaserCheckerboard = (p: Props) => {
  let offset = 0;
  return (
    <CheckerboardComponent>
      {p.entries.map((el, i) => {
        if (!el.image) offset++;
        return (
          <ImageTextTeaserComponent
            {...el}
            small
            blue={p.blue}
            bottomMargin
            deeperHeading={p.deeperHeading}
            html={typeof el.text !== "string"}
            key={i}
            swap={(offset + i) % 2 ? true : false}
          />
        );
      })}
    </CheckerboardComponent>
  );
};

ImageTextTeaserCheckerboard.getImages = (props: Props) => {
  let images = [];
  props.entries.forEach(e => {
    images = images.concat(ImageTextTeaserComponent.getImages(e));
  });
  return images;
};
