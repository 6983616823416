import React, {
  useState,
  ReactNode,
  useRef,
  useEffect,
  useCallback
} from "react";
import Label from "./Label";
import Headline from "./Headline";
import Button from "./Button";
import { ContentRef } from "../../lib/CmsApi";
import styled, { css } from "styled-components";
import {
  media,
  spacing,
  colors,
  font,
  fontSizing,
  breakpoints
} from "../theme";
import RichText from "./RichText";
import { generateAutoPlacementForIE } from "./Grid";
import { HEIGHT } from "../Header/constants";
import shortUnique from "../../lib/shortUnique";
import useMediaQuery from "../../lib/useMediaQuery";

const MainContainer = styled.div<{ showMore: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  // justify-content: flex-end;
  // align-items: flex-start;
  // margin-top: 40px;
  // padding-bottom: 48px;

  max-width: 994px;
  // height: ${p => (p.showMore ? "350px" : "446px")};

  @media screen and ${media.tablet} {
    margin-bottom: 100px;
  }

  @media screen and ${media.mobile} {
    width: 100%;
    // margin-top: 300px;
    margin-bottom: 18px;
  }

  @media screen and ${media.tiny} {
    width: 100%;
    // margin-top: 40px;
    margin-bottom: 10px;
  }

  @media screen and ${media.superTiny} {
    // margin-top: 120px;
    margin-bottom: 10px;
  }
`;

const BannerContainer = styled.div<{ showMore: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 350px;

  margin-top: 48px;
  margin-bottom: 48px;

  @media screen and ${media.tablet} {
    width: 100%;
  }

  @media screen and ${media.mobile} {
    width: 100%;
    height: auto;
    left: 0px;
    top: 0px;
    // padding-top: 32px;
    // padding-bottom: 32px;
  }

  @media screen and ${media.tiny} {
    // padding-top: 120px;
    margin-top: 40px;
  }
`;

const TextContainer = styled.div<{ showMore: boolean }>`
  @media screen and ${media.tiny} {
    margin-bottom: ${p => (p.showMore ? "40px" : "")};
    -webkit-mask-image: ${p =>
      p.showMore
        ? ""
        : `-webkit-gradient(
        linear,
        left center,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      )`};
    transition: all 0.4s;
  }
`;

const StyledOverline = styled(Label)`
  grid-area: overline;
`;

const StyledTitle = styled(Headline)`
  grid-area: title;
`;
const StyledSubline = styled(Label)`
  grid-area: underline;
`;

const StyledText = styled.div`
  font-weight: 390;
  font-size: 18px;
  line-height: 1.8;
  color: #4c4c4c;
  grid-area: paragraph;
  /* fix because richtext adds bottom margin already, but richtext content could be string only */
  > p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    font-weight: bold;
    text-decoration-color: ${colors.lightBlue};
    border-bottom: 1px solid ${colors.lightBlue};
    padding-bottom: 3px;
    cursor: pointer;
    font-family: ${font.main};
  }

  @media screen and ${media.tablet} {
    font-size: 16px;
  }

  @media screen and ${media.mobile} and ${media.tiny} {
    line-height: 28px;
  }
`;
const ctaStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  gap: 10px;

  width: 204px;
  height: 48px;

  background: #004669;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #ffffff;
  white-space: nowrap;
  text-align: center;
  margin-top: 5px;
`;
const StyledCTA = styled(Button)`
  ${ctaStyle}
`;

const ShowMoreButton = styled.button<{ showMore: boolean }>`
  width: 100%;
  height: auto;
  align-self: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  gap: 10px;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: #004669;
  white-space: nowrap;
  text-align: center;

  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 420;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
`;

const getShortText = (text: string, minChars = 300) => {
  const regex = RegExp("[A-Za-zäöüß,;'\"]+[.?!]", "g");
  let result = regex.exec(text);
  while (result) {
    if (result.index > minChars) {
      return text.substring(0, result[0].length + result.index);
    } else result = regex.exec(text);
  }
  return text;
};

export type EssentialTeaserProps = {
  id: string;
  headline: string;
  overline: string | ReactNode;
  subline?: string;
  ghost?: boolean;
  text: string;
  layoutType?: boolean;
  brandon?: boolean;
  href: string;
  ctaLabel: string;
  ctaRef: ContentRef | { href: string };
} & (
  | {
      ctaLabel: string;
      ctaRef: ContentRef | { href: string };
    }
  | {
      collapseStyle: boolean;
    });

export default function EssentialTeaserComponent(p: EssentialTeaserProps) {
  const { brandon = true, ghost = true, id: pId, ...rest } = p;
  const labelRef = useRef<HTMLLabelElement>();
  const isMobile = useMediaQuery(breakpoints.tiny);
  const isTablet = useMediaQuery(breakpoints.tablet);
  const [showMore, setShowMore] = useState(false);

  function handleChange() {
    if (!showMore) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }

  let shortText,
    text = p.text;
  if (isMobile) {
    shortText = getShortText(p.text);
    if (isMobile) text = shortText;
  } else {
    text = p.text;
  }

  return (
    <MainContainer showMore={showMore}>
      <BannerContainer showMore={showMore}>
        <StyledOverline key="overline" ref={labelRef}>
          {p.overline}
        </StyledOverline>
        <StyledTitle
          size={3}
          key="title"
          level={2}
          id={`essentialTeaser_${p.id}`}
        >
          {p.headline}
        </StyledTitle>
        {p.subline ? (
          <StyledSubline big key="subline">
            {p.subline}
          </StyledSubline>
        ) : (
          <div key="subline_empty" />
        )}
        <TextContainer showMore={showMore}>
          <StyledText
            dangerouslySetInnerHTML={{ __html: text ? text : "" }}
            key="text"
          />
        </TextContainer>
        {/* {showMore && (
          <StyledCTA
            key="cta_desktop"
            ghost={ghost}
            href={"_url" in p.ctaRef ? p.ctaRef._url : p.href}
            aria-label={p.ctaLabel + ", " + p.headline}
          >
            {p.ctaLabel}
          </StyledCTA>
        )} */}
        {isMobile && !showMore && (
          <ShowMoreButton
            key="show_more_button"
            aria-label={"show more button"}
            onClick={handleChange}
            showMore={showMore}
          >
            {"Mehr anzeigen"}
          </ShowMoreButton>
        )}
      </BannerContainer>
    </MainContainer>
  );
}
