import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors, font, fontSizing, spacing, media } from "../theme";

const StyledQuote = styled.p`
  display: block;
  margin: 0;
  font-family: ${font.secondary};
  color: ${colors.darkgrey};
  font-weight: ${font.weightLight};
  ${spacing("margin-bottom", 40)}
  ${fontSizing("text", 48)}
`;

const Author = styled.footer`
  display: inline;
  font-weight: 500;
  ${fontSizing("text", 24)}
`;

const Blockquote = styled.blockquote`
  @media screen and ${media.mobile} {
    margin-left: 0;
    margin-right: 0;
  }
`;

interface Props {
  children: ReactNode | ReactNode[];
  author: string;
}

const Quote = (p: Props) => (
  <Blockquote>
    <StyledQuote>{p.children}</StyledQuote>
    <Author>{p.author}</Author>
  </Blockquote>
);

export default Quote;
