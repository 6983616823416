import React, { useState, ReactNode, useRef } from "react";
import Label from "../common/Label";
import Headline from "../common/Headline";
import Button from "../common/Button";
import { RouteName, RouteParams } from "../../lib/routes";
import { ContentRef } from "../../lib/CmsApi";
import styled, { css } from "styled-components";
import { media, spacing, colors } from "../theme";
import RichText from "./RichText";
import { generateAutoPlacementForIE } from "./Grid";
import { HEIGHT } from "../Header/constants";
import shortUnique from "../../lib/shortUnique";

const commonLayout = (p: { children?: ReactNode | ReactNode[] }) =>
  p.children &&
  css`
    @media screen and ${media.mobile} {
      ${generateAutoPlacementForIE(p.children, "1fr", {
        column: 4 * 8,
        row: 0
      })}
      grid-template-columns: 1fr;
      grid-template-areas:
        "overline"
        "title"
        "underline"
        "paragraph"
        "cta";
    }
  `;
const LayoutA = styled.div<{ withSubline?: boolean }>`
  display: -ms-grid;
  display: grid;
  width: 100%;
  ${p =>
    p.withSubline
      ? css`
          grid-auto-rows: auto;
          column-gap: calc(4px * 8);
          -ms-grid-columns: 9fr calc(4px * 8) 3fr;
          -ms-grid-rows: auto auto auto auto;
          grid-template-columns: 9fr 3fr;
          grid-template-areas:
            "overline ."
            "title ."
            "paragraph underline"
            "cta .";
          > *:nth-child(1) {
            -ms-grid-column: 1;
            -ms-grid-row: 1;
          }
          > *:nth-child(2) {
            -ms-grid-column: 1;
            -ms-grid-row: 2;
          }
          > *:nth-child(3) {
            -ms-grid-column: 3;
            -ms-grid-row: 3;
          }
          > *:nth-child(4) {
            -ms-grid-column: 1;
            -ms-grid-row: 3;
          }
          > *:nth-child(5),
          > *:nth-child(6) {
            -ms-grid-column: 1;
            -ms-grid-row: 4;
          }
        `
      : css`
          grid-template-columns: 1fr;
          max-width: calc(9 / 12 * 100%);
          grid-template-areas:
            "overline"
            "title"
            "paragraph"
            "cta";
          @media screen and ${media.mobile} {
            width: inherit;
            max-width: inherit;
          }
          ${generateAutoPlacementForIE(p.children, "1fr", {
            column: 4 * 8,
            row: 0
          })}
        `}

  /* @media screen and ${media.wide} {
    grid-template-columns: 1fr ${p => (p.withSubline ? "400px" : "300px")};
  } */

  ${p => commonLayout(p)}
`;

const LayoutB = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 400px calc(4px * 8) 1fr;
  -ms-grid-rows: minmax(min-content, max-content)
    minmax(min-content, max-content) 1fr minmax(min-content, max-content);
  column-gap: calc(4px * 8);
  grid-template-columns: 400px 1fr;
  grid-template-rows: repeat(2, minmax(min-content, max-content)) 1fr minmax(
      min-content,
      max-content
    );
  grid-template-areas:
    "overline paragraph"
    "title paragraph"
    "underline paragraph"
    ". cta";
  > *:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  > *:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }
  > *:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
  > *:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
  }
  > *:nth-child(5),
  > *:nth-child(6) {
    -ms-grid-column: 3;
    -ms-grid-row: 4;
  }
  @media screen and ${media.wide} {
    -ms-grid-columns: 600px calc(4px * 8) 1fr;
    grid-template-columns: 600px 1fr;
  }
  @media screen and ${media.tablet} {
    -ms-grid-columns: 300px calc(4px * 8) 1fr;
    grid-template-columns: 300px 1fr;
  }
  ${p => commonLayout(p)}
`;

const StyledOverline = styled(Label)`
  grid-area: overline;
`;
const StyledTitle = styled(Headline)`
  grid-area: title;
`;
const ctaStyle = css`
  grid-area: cta;
  justify-self: flex-start;
  -ms-grid-row-align: start;
  -ms-grid-column-align: start;
  ${spacing("margin-top", 48)};
`;
const StyledCTA = styled(Button)`
  ${ctaStyle}
`;
const CTAContainer = styled.div`
  ${ctaStyle}
`;
const StyledSubline = styled(Label)`
  grid-area: underline;
`;
const StyledRichText = styled(RichText)`
  grid-area: paragraph;
  /* fix because richtext adds bottom margin already, but richtext content could be string only */
  > p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    font-weight: bold;
    text-decoration-color: ${colors.lightBlue};
    border-bottom: 1px solid ${colors.lightBlue};
    padding-bottom: 3px;
    cursor: pointer;
  }
`;
const getShortText = (text: string, minChars: number = 300) => {
  const regex = RegExp("[A-Za-zäöüß,;'\"]+[.?!]", "g");
  let result = regex.exec(text);
  while (result) {
    if (result.index > minChars) {
      return text.substring(0, result[0].length + result.index);
    } else result = regex.exec(text);
  }
  return text;
};
/*
TODO: noch genutzt?
const getShortTextByTag = text => {
  const regex = RegExp("</p>|<br/>", "g");
  const result = regex.exec(text);
  return result ? text.substring(0, result[0].length + result.index) : text;
};
*/
export type TextTeaserProps = {
  id: string;
  headline: string;
  overline: string | ReactNode;
  subline?: string;
  ghost?: boolean;
  text: string;
  layoutType?: boolean;
  serif?: boolean;
} & (
  | {
      ctaLabel: string;
      ctaRef: ContentRef | { route: RouteName; params: RouteParams };
    }
  | {
      collapseStyle: boolean;
    });

export default function TextTeaserComponent(p: TextTeaserProps) {
  const { serif = true, ghost = true, layoutType, id: pId, ...rest } = p;
  const ref = useRef<HTMLDivElement>();
  const labelRef = useRef<HTMLLabelElement>();
  const [isCollapsed, setIsCollapsed] = useState(
    "collapseStyle" in p && p.collapseStyle
  );
  const [id] = useState("id" in p ? pId : shortUnique());
  // TODO: useEffect
  let shortText,
    text = p.text;
  if ("collapseStyle" in p && p.collapseStyle) {
    shortText = getShortText(p.text);
    if (isCollapsed) text = shortText;
  }

  const content = [
    <StyledOverline key="overline" ref={labelRef}>
      {p.overline}
    </StyledOverline>,
    <StyledTitle size={3} key="title" level={2} id={`textTeaser_${id}`}>
      {p.headline}
    </StyledTitle>,
    p.subline ? (
      <StyledSubline big key="subline">
        {p.subline}
      </StyledSubline>
    ) : (
      <div key="subline_empty" />
    ),
    <StyledRichText
      html={text}
      serif={serif}
      key="richText"
      aria-expanded={
        "collapseStyle" in p && p.collapseStyle ? !isCollapsed : undefined
      }
      id={
        "collapseStyle" in p && p.collapseStyle
          ? `textTeaserCollapse_${id}`
          : undefined
      }
    />,
    "ctaRef" in p && (
      <StyledCTA
        key="cta"
        ghost={ghost}
        href={"_url" in p.ctaRef ? p.ctaRef._url : undefined}
        route={"route" in p.ctaRef ? p.ctaRef.route : undefined}
        params={"params" in p.ctaRef ? p.ctaRef.params : undefined}
        aria-label={p.ctaLabel + ", " + p.headline}
      >
        {p.ctaLabel}
      </StyledCTA>
    ),
    "collapseStyle" in p &&
      p.collapseStyle &&
      p.text.length !== shortText.length && (
        <CTAContainer key="more" ref={ref}>
          <Button
            ghost
            onclick={() => {
              if (
                !isCollapsed &&
                labelRef.current &&
                "scrollIntoView" in labelRef.current
              ) {
                setTimeout(() => {
                  labelRef.current.scrollIntoView({ behavior: "auto" });
                  if (window) window.scrollBy(0, -HEIGHT);
                }, 0);
              }
              setIsCollapsed(!isCollapsed);
            }}
            collapseStyle
            collapsed={isCollapsed}
            aria-controls={`textTeaserCollapse_${id}`}
            aria-label={
              (isCollapsed ? "Mehr lesen" : "Weniger lesen") + ", " + p.headline
            }
          >
            {isCollapsed ? "Mehr lesen" : "Weniger"}
          </Button>
        </CTAContainer>
      )
  ];
  return layoutType ? (
    <LayoutA withSubline={!!p.subline} {...rest}>
      {content}
    </LayoutA>
  ) : (
    <LayoutB {...rest}>{content}</LayoutB>
  );
}
