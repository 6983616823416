import React from "react";
import styled, { css } from "styled-components";
import { media, breakpoints } from "../../theme";
import { Image as ImageType } from "../../../lib/image";
import { ContentRef } from "../../../lib/CmsApi";
import { responsiveBackgroundImage } from "../../../lib/responsiveImage";
import getImageFocus from "../../../lib/getImageFocus";
import Headline from "../Headline";
import useMediaQuery from "../../../lib/useMediaQuery";

type CrossTeaserProps = {
  id?: string;
  headline?: string;
  image?: ImageType;
  cardSize?: CardSizes;
  buttonTitle: string;
  link: ContentRef;
};

type CardSizes = "sm" | "md" | "lg";

const CrossTeaser = styled.div`
  background: #ffffff;

  width: 100%;
  height: 306px;

  cursor: pointer;

  &:hover {
    box-shadow: inset 0 -4px 0 0 #e10019;
  }

  @media screen and ${media.tablet} {
    height: 220px;
  }

  @media screen and ${media.tiny} {
    height: 202px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media screen and ${media.mobile} {
    width: 90%;
  }
`;

const CrossTeaserSm = styled.div`
  width: 100%;
  height: 374px;

  background: #ffffff;

  cursor: pointer;

  &:hover {
    box-shadow: inset 0 -4px 0 0 #e10019;x
  }

  @media screen and ${media.tablet} {
    height: 250px;
    width: 93%;
  }
`;

const CrossTeaserLg = styled.div`
  width: 100%;
  height: 404px;

  background-color: #ffffff;

  cursor: pointer;
  &:hover {
    box-shadow: inset 0 -4px 0 0 #e10019;
  }

  @media screen and ${media.tablet} {
    height: 250px;
  }
`;

const Title = styled(Headline)`
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 16px;
  margin-left: 10px;
  padding-top: 5px;
`;

const TabletTitle = styled.h6`
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 390;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 16px;
  margin-left: 10px;
`;

const ImageContainer = styled.div<{ cardSize: string }>`
  width: 100%;

  height: ${p =>
    p.cardSize === "md" ? "164px" : p.cardSize === "lg" ? "262px" : "232px"};

  @media screen and ${media.tablet} {
    height: ${p => (p.cardSize === "md" ? "120px" : "150px")};
  }

  @media screen and ${media.mobile} {
    height: ${p => (p.cardSize === "md" ? "92px" : "122px")};
  }
`;

const LowerContainer = styled.div`
  width: 100%;
  height: 142px;

  margin-bottom: 20px;

  @media screen and ${media.tablet} {
    height: 150px;
  }
`;

const CrossTeaserImage = styled.div<{ image: ImageType; cardSize: string }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: ${p => getImageFocus(p.image)};
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  ${p => responsiveBackgroundImage({ image: p.image })};

  height: ${p =>
    p.cardSize === "md" ? "164px" : p.cardSize === "lg" ? "262px" : "232px"};

  @media screen and ${media.tablet} {
    height: ${p => (p.cardSize === "md" ? "120px" : "150px")};
  }

  @media screen and ${media.mobile} {
    height: ${p => (p.cardSize === "md" ? "100px" : "150px")};
  }
`;

const ctaStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  width: fit-content;
  height: 48px;

  background: #004669;

  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #004669;
  background-color: #ffffff;

  border: 2px solid #004669;
  /* Shadow Outer Blue */

  border-radius: 4px;

  white-space: nowrap;
  margin-top: 10px;

  overflow: visible;
  padding: 2px 5px 2px 5px;
  margin-right: 10px;
  text-align: center;
  line-height: 40px;

  @media screen and ${media.tablet} {
    width: fit-content;
    height: 30px;
    padding: 5px 10px 5px 10px;
    line-height: 15px;
    font-size: 14px;
  }
`;
const StyledCTA = styled.a`
  ${ctaStyle};
  margin-top: 16px;
  margin-left: 10px;
  text-decoration: none;
  &:hover {
    color: #3c6c92;
    border: 2px solid #3c6c92;
  }
`;

function CrossteaserArrow({
  id,
  headline,
  image,
  cardSize,
  link,
  buttonTitle
}: CrossTeaserProps) {
  const isTablet = useMediaQuery(breakpoints.tablet);

  const contents = (
    <>
      <ImageContainer cardSize={cardSize}>
        <CrossTeaserImage cardSize={cardSize} image={image} />
      </ImageContainer>
      <LowerContainer style={{ width: "auto" }}>
        {isTablet ? (
          <TabletTitle>{headline}</TabletTitle>
        ) : (
          <Title
            style={{
              paddingTop: media.wide ? "20px" : media.desktop ? "20px" : "5px",
              overflowWrap: "break-word"
            }}
            size={cardSize === "sm" ? 5 : 4}
            level={cardSize === "sm" ? 5 : 4}
          >
            {headline}
          </Title>
        )}
        <StyledCTA
          key="document_link"
          href={link._id ? `/api/services/document/${link._id}` : ""}
          aria-label={"document_download" + ", " + headline}
          target="_blank"
          tabIndex={0}
        >
          {buttonTitle}
        </StyledCTA>
      </LowerContainer>
    </>
  );

  return cardSize === "md" ? (
    <CrossTeaser>{contents}</CrossTeaser>
  ) : cardSize === "lg" ? (
    <CrossTeaserLg>{contents}</CrossTeaserLg>
  ) : (
    <CrossTeaserSm>{contents}</CrossTeaserSm>
  );
}

export default CrossteaserArrow;
