import React from "react";
import InitialPropsContext from "../lib/models/initialPropsContext";
import PageNotFoundError from "../lib/pageNotFoundError";
import SectionList, { SectionWithId } from "../components/sections/SectionList";
import PageProps from "../lib/models/pageProps";
export default function Page(props: { contents: SectionWithId[] }) {
  return <SectionList sections={props.contents} main />;
}

Page.getInitialProps = async ({
  api
}: InitialPropsContext): PageProps<{ contents: SectionWithId[] }> => {
  const [index, news, portraits, events] = await Promise.all([
    api.loadIndex().catch(() => {
      throw new PageNotFoundError();
    }),
    api
      .listMagazinNews({
        orderBy: "date",
        order: "desc",
        limit: 3
      })
      .catch(() => ({ total: 0, items: [] })),
    api
      .listMagazinPortraits({
        orderBy: "date",
        order: "desc",
        limit: 6
      })
      .catch(() => ({ total: 0, items: [] })),
    api
      .listExternalEvents({
        offset: 0,
        limit: 10000,
        fields: ["name", "description", "ctaHref", "organizer", "dates"]
      })
      .catch(() => ({ total: 0, items: [] }))
  ]);
  events.items.sort((d1, d2) => {
    if (d1.dates[0].date > d2.dates[0].date) return 1;
    if (d1.dates[0].date < d2.dates[0].date) return -1;
    return 0;
  });
  const now = new Date();
  const clearEvents = events.items.filter(({ dates }) => {
    const d1 = new Date(dates[0].date);
    const d2 = dates[1] ? new Date(dates[1].date) : undefined;

    return !(d2 && d2 < now) && !(!d2 && d1 && d1 < now);
  });

  const contents: SectionWithId[] = [
    ...index.contents.map<SectionWithId>((c: SectionWithId) => {
      if (c._type === "Magazin" && news && portraits) {
        c.news = news.items;
        c.events.items = clearEvents.slice(0, 6);
        c.stories = portraits.items;
      }
      return c;
    })
  ];
  const heroSlider =
    index.hasOwnProperty("HeroSlider") && (index as any).HeroSlider;
  if (
    index.hasOwnProperty("HeroSlider") &&
    heroSlider.sliderItems.length !== 0
  ) {
    contents.splice(0, 0, {
      ...heroSlider,
      _type: "HeroSlider" as "HeroSlider"
    });
  } else {
    const finder = index.hasOwnProperty("finder") && (index as any).finder;
    contents.unshift({ ...finder, _type: "Finder" as "Finder" });
  }

  let _pageImages;
  try {
    _pageImages = SectionList.getImages({ sections: contents });
  } catch (error) {
    console.error(error);
  }
  return {
    contents,
    _pageImages,
    seo: index.seo
  };
};
